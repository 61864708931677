import React, { useEffect, useState, ReactDOM } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { RootState } from "../../store";
import { IEquipmentPhoto } from "../../store/userData/types";
import {connect, ConnectedProps} from "react-redux";
import MapPopup from './MapPopup';
import { Popup, Marker } from 'react-leaflet';
import { Icon} from 'leaflet';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    imagePopup: {
        width: 'fit-content',
        maxWidth: '100vw'
    }
}));

interface Props {
}

const MapPhotos = (props: Props & PropsFromRedux) => {
    const classes = useStyles();
    let photosLabel: any  = [];

    if (props.allDailyData && props.allDailyData.length > 0) {
        props.allDailyData.map(polyLine => {
          if (polyLine.showPhoto) {
            photosLabel = photosLabel.concat(polyLine.photos.map(photo => {
              const locs = polyLine.locations.filter(loc => Math.abs(loc.timestamp - photo.timestamp) < 60000);
              if (locs.length > 0) {
                return {coords: locs[0].coords, photo};
              }
              return null;
            }).filter(v => v));
          } 
        });
    }

    return (
        <>
          {photosLabel && photosLabel.map((photoLabelItem: any) => (
            <Marker key={photoLabelItem.photo.photoIconURL} position={photoLabelItem.coords} title={'Фото'} icon={new Icon({iconUrl: '/' + photoLabelItem.photo.photoIconURL})}>
                <Popup className={classes.imagePopup}>
                    <MapPopup item={photoLabelItem.photo.photoURL} type={2}/>
                </Popup>
            </Marker>
          ))}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
      allDailyData: state.userData.allDailyData
    };
  };
  
 
  const connector = connect(mapStateToProps, {});
  type PropsFromRedux = ConnectedProps<typeof connector>
  
  export default connector(MapPhotos);
