import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {connect, ConnectedProps} from "react-redux";
import { GET_PRIORITY_ORDERS, IPriorityOrder } from '../../store/userData/types';
import Cards from '../containers/Cards';

const useStyles = makeStyles((theme: any) => ({
    articleImg: {
    }
}));

interface PriorityOrdersProps{
}

const PriorityOrders: React.FC<PriorityOrdersProps & PropsFromRedux> = props => {
    const classes = useStyles();
    const { getPriorityOrders, auth, priorityOrders } = props;

    useEffect(() => {
        getPriorityOrders();
    }, []);

    return (
        <Cards auth={auth} items={priorityOrders} title={'Избранные заказы'} url={'priority-order'}  search={true}/>    
    );
};

interface RootState {
    auth: { auth: boolean };
    userData: { priorityOrders: IPriorityOrder[] };
}

const mapStateToProps = (state: RootState) => {
    return {
        priorityOrders: state.userData.priorityOrders,
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getPriorityOrders: () => dispatch({ type: GET_PRIORITY_ORDERS }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PriorityOrders);
