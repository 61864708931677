import { LatLng } from 'leaflet';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { CHANGE_CENTER } from '../map/types'
import {
    SET_AUTH,
    LOGIN,
    LOGOUT,
    Login,
    SIGNUP,
    Logout, GET_USER, GetUser, Signup, AddPartner, CheckNickname, SET_CONFIRMED_NICKNAME, SET_NICKNAME, SetNickname,
    SearchUserName, SEARCH_USER_NAME, SET_FOUNDED_USERS, ADD_PARTNER, SavePartnersPermissions, SAVE_PARTNERS_PERMISSIONS, IPartner, CHECK_NICKNAME
} from './types'

function* loginSaga(action: Login) {
    try {
        const result = yield call(login, action.email, action.password);
        return yield put({ type: SET_AUTH, auth: result.auth, user: result.user, message: result.msg });
    } catch (e) { }
}

function* signupSaga(action: Signup) {
    try {
        const result = yield call(signup, action.email, action.password);
        return yield put({ type: SET_AUTH, auth: result.auth, user: result.user, message: result.msg });
    } catch (e) { }
}

function* logoutSaga(action: Logout) {
    try {
        const result = yield call(logout);
        return yield put({ type: SET_AUTH, auth: result.auth, user: null });
    } catch (e) { }
}

function* fetchUserSaga(action: GetUser) {
    try {
        const result = yield call(fetchUser);

        yield put({ type: CHANGE_CENTER, coords: new LatLng(result.location.lat || 55.573562, result.location.lng || 38.267461), klm: 30 });
        return yield put({ type: SET_AUTH, auth: result.auth, user: result.user, partners: result.partners });
    } catch (e) { }
}

function* searchNameSaga(action: SearchUserName) {
    try {
        const result = yield call(searchUserByName, action.name);
        return yield put({ type: SET_FOUNDED_USERS, similarUsers: result.similarUsers });
    } catch (e) { }
}

function* addPartnerSaga(action: AddPartner) {
    try {
        const result = yield call(addPartner, action.name);
        return yield put({ type: GET_USER });
    } catch (e) { }
}

function* savePermissionsSaga(action: SavePartnersPermissions) {
    try {
        const result = yield call(savePermissions, action.partners);
        return yield put({ type: GET_USER });
    } catch (e) { }
}

function* checkNicknameSaga(action: CheckNickname) {
    try {
        yield put({ type: SET_CONFIRMED_NICKNAME, name: '', message: 'Проверка никнейма ' + action.name + '...' });
        const result = yield call(checkNickname, action.name);
        return yield put({ type: SET_CONFIRMED_NICKNAME, name: result.name, message: result.message });
    } catch (e) { }
}

function* setNicknameSaga(action: SetNickname) {
    try {

        yield call(setNickname, action.name);
        yield put({ type: SET_CONFIRMED_NICKNAME, name: '', message: '' });
        return yield put({ type: GET_USER });
    } catch (e) { }
}

function* authSaga() {
    yield takeLatest(SET_NICKNAME, setNicknameSaga);
    yield takeLatest(CHECK_NICKNAME, checkNicknameSaga);
    yield takeLatest(SAVE_PARTNERS_PERMISSIONS, savePermissionsSaga);
    yield takeLatest(ADD_PARTNER, addPartnerSaga);
    yield takeLatest(SEARCH_USER_NAME, searchNameSaga);
    yield takeLatest(SIGNUP, signupSaga);
    yield takeLatest(LOGIN, loginSaga);
    yield takeLatest(LOGOUT, logoutSaga);
    yield takeLatest(GET_USER, fetchUserSaga);
}

function setNickname(name: string) {
    return fetch("/api/set-nickname/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ name })
    })
        .then(response => response.json())
}

function checkNickname(name: string) {
    return fetch("/api/check-nickname/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ name })
    })
        .then(response => response.json())
}

function login(email: string, password: string) {
    return fetch("/api/login/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ email, password })
    })
        .then(response => response.json())
}

function signup(email: string, password: string) {
    return fetch("/api/signup/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ email, password })
    })
        .then(response => response.json())
}

function logout() {
    return fetch("/api/logout", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function fetchUser() {
    return fetch("/api/get-user", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function searchUserByName(name: string) {
    return fetch("/api/get-user-by-name?name=" + name, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function addPartner(name: string) {
    return fetch("/api/addPartner", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ name })
    })
        .then(response => response.json())
}

function savePermissions(partners: IPartner[]) {
    return fetch("/api/save-partners", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ partners })
    })
        .then(response => response.json())
}

export default authSaga;