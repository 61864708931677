import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Rectangle } from 'react-leaflet';
import { LatLng } from 'leaflet';
import { RootState } from "../../store";
import { getMax, getMin } from "../../store/userData/reducers";
import MapPathLines from '../containers/MapPathLines';
import Slider from '@material-ui/core/Slider';
import { IAllDailyData, IEquipmentCoord, IEquipmentPhoto } from '../../store/userData/types';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const MLSEC_IN_DAY = 1000 * 60 * 60 * 24;
const MLSEC_IN_MINUTES = 1000 * 60;

interface SpeedGraph {
    day: number,
    graph: { x: number, speed: number, timestamp: number }[],
    photos: IEquipmentPhoto[],
    maxSpeed: number
};

const useStyles = makeStyles((theme) => ({
    root: {
    },
    slider: {
        width: 'calc(-32px + 100%)',
        position: 'absolute',
        bottom: '8px',
        padding: '2px 8px 8px 8px',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    currentSlider: {
        display: 'flex',
        color: '#fff',
        justifyContent: 'space-between'
    },
    currentSpeed: {
        fontSize: '16px',
        color: '#0f0',
    },
    formControl: {
        margin: '8px auto',
        minWidth: 280,
        maxWidth: 960,
        display: 'block'
    },
    select: {
        minWidth: 280,
        width: '100%'
    },
    image: {
        maxWidth: "300px",
        marginTop: '8px'
    },
    marginTop: {
        marginTop: '8px'
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        maxWidth: "100px",
        padding: '4px',
        overflow: 'hidden'
    },
    tableIco: {
        maxWidth: "100px",
        maxHeight: "30px"
    },
    tableEquipName: {
        textAlign: 'center',
        textOverflow: 'ellipsis'
    },
    tableIcoWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    tableWrapper: {
        maxWidth: '100%'
    },
    axleX: {
        color: '#849DBB',
        height: '41px',
        position: 'absolute',
        bottom: '1px',
        left: '27px',
        width: 'calc(100% - 36px)'
    },
    row: {
        color: '#849DBB',
        height: '230px',
    },
    axleY: {
        height: '25px',
        width: '23px',
        display: 'inline-block',
        textAlign: 'right',
        paddingRight: '4px'
    },
    digramContent: {
        display: 'inline-block',
        width: 'calc(100% - 37px)',
        borderBottom: '1px dashed #000'
    },
    xValue: {
        display: 'inline-block',
        borderTop: '1px solid #849DBB',
        width: '14.14%',
        fontSize: '13px'
    },
    svgDiagram: {
        position: 'absolute',
        bottom: '41px',
        left: '27px',
        width: 'calc(100% - 36px)',
        height: '120px'
    },
    diagramWrapper: {
        height: '156px',
        position: 'relative',
        overflow: 'hidden',
        overflowX: 'auto'
    },
    zoomWrapper: {
        width: '100%'
    },
    svg: {
        overflow: 'visible',
        marginTop: '22px'
    },
    dot: {
        cursor: 'pointer',
        '&:hover': {
            strokeWidth: '3',
            stroke: "white"
        }
    },
}));

interface Props {
    equipmentId: string
}

const EquipmetnLocations = (props: Props & PropsFromRedux) => {
    const classes = useStyles();
    const marker = new LatLng(props.center.lat || 55.75, props.center.lng || 37.61);
    const [map, setMap] = useState<any>(null);
    const [rows, setRows] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sliderValue, setSliderValue] = useState(0);
    const [currentSpeed, setCurrentSpeed] = useState(0);
    const [currentPhoto, setCurrentPhoto] = useState<IEquipmentPhoto | null>(null);
    const [fullSize, setFullSize] = useState(false);
    const [flyToCurrent, setFlyToCurrent] = useState(false);
    const [minTime, setMinTime] = useState(0);
    const [maxTime, setMaxTime] = useState(0);
    const [checkedPaths, setCheckedPaths] = useState<IAllDailyData | null>(null);
    const [speedGraphs, setSpeedGraphs] = useState<SpeedGraph[]>([]);
    const timezoneOffset = (new Date()).getTimezoneOffset() * MLSEC_IN_MINUTES;

    useEffect(() => {
        let speed = 0;
        let photo = null;
        speedGraphs?.map(item => {
            item.graph.map((graph: any, index: number) => {
                if (index > 0) {
                    if ((item.graph[index - 1].timestamp <= sliderValue) && (item.graph[index].timestamp > sliderValue)) {
                        speed = item.graph[index - 1].speed;
                    }
                }
            })
        });
        checkedPaths?.photos.map((item: IEquipmentPhoto, index: number) => {
            if (index > 0) {
                if ((checkedPaths.photos[index - 1].timestamp <= sliderValue) && (checkedPaths.photos[index].timestamp > sliderValue)) {
                    photo = { ...checkedPaths.photos[index - 1] };
                }
            }
        })
        if (flyToCurrent) {
            const curentCoords = checkedPaths?.locations.reduce((accum: any, item: any, i: number, arr: IEquipmentCoord[]) => { if ((i > 0) && (arr[i - 1].timestamp <= sliderValue) && (arr[i].timestamp > sliderValue)) { return arr[i - 1].coords; } return accum; }, null);
            if (map && curentCoords) {
                map.flyTo(new LatLng(curentCoords[0], curentCoords[1]), 12);
            }
        }
        setCurrentSpeed(speed);
        setCurrentPhoto(photo);
    }, [sliderValue]);

    const onSliderChange = (event: any, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onRowClick = (index: number) => {
        const graphs: SpeedGraph[] = [];
        const checkedRows: IAllDailyData = { locations: [], photos: [], equipment: props.currentEquipment!, showPath: true, equipmentId: '', showPhoto: false, showTime: false, pathInterval: 100 };
        const newRows = rows.map((row: any, i: number) => {
            if (index === i) {
                row.selected = !row.selected;
            }
            if (row.selected) {
                const maxSpeed = row.locations.reduce((accum: number, item: any) => ((item.speed || 0) > accum) ? (item.speed || 0) : accum, 1);
                checkedRows.locations = checkedRows.locations.concat(row.locations);
                checkedRows.photos = checkedRows.photos.concat(row.photos);
                graphs.push({
                    day: row.dayStartTimestamp + timezoneOffset,
                    graph: row.locations.map((loc: any) => {
                        const day = (new Date(loc.timestamp));
                        day.setUTCHours(0, 0, 0, 0);
                        const delta = ((loc.timestamp - (day.getTime() + timezoneOffset)) / MLSEC_IN_DAY) * 100;
                        return { x: delta, speed: loc.speed || 0, timestamp: loc.timestamp };
                    }),
                    photos: row.photos,
                    maxSpeed: maxSpeed
                });
            }
            return row;
        });
        setSpeedGraphs(graphs);
        setRows(newRows);
        setCheckedPaths(checkedRows);
        setMinTime(getMin([checkedRows], null) || 0);
        setMaxTime(getMax([checkedRows], null) || 0);
        if (map && checkedRows.locations.length) {
            map.flyTo(new LatLng(checkedRows.locations[0].coords[0], checkedRows.locations[0].coords[1]), 12);
        }
    };

    const mapCreated = (map: any) => { setMap(map) };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        fetch(`/api/get-daily-data-table?offset=${rowsPerPage * page}&limit=${rowsPerPage}&equipmentId=${props.equipmentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(response => response.json()).then(response => { setRows(response.locations); setCount(response.count); })
    }, [page, rowsPerPage]);

    return (
        <>
            {rows &&
                <div className={classes.tableWrapper}>
                    <Paper>
                        <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="center">Дата</TableCell>
                                        <TableCell align="center">Количество фото</TableCell>
                                        <TableCell align="center">Количество GPS-точек</TableCell>
                                        <TableCell align="center">Пройденный путь, км</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows?.map((item: any, index: number) => (
                                        <TableRow key={index}>
                                            <Checkbox
                                                checked={item.selected}
                                                onClick={() => { onRowClick(index) }}
                                                inputProps={{ 'aria-labelledby': index.toString() }}
                                            />
                                            <TableCell>{(new Date(item.dayStartTimestamp || 0)).toLocaleDateString()}</TableCell>
                                            <TableCell align="center">{item.photos?.length || 0}</TableCell>
                                            <TableCell align="center">{item.locations?.length || 0}</TableCell>
                                            <TableCell align="center">{(item.distance || 0).toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>

                    <Paper style={{ position: 'relative' }}>
                        {
                            !!minTime && !!maxTime && <div className={classes.slider}>
                                {<div className={classes.currentSlider}>
                                    <div>{new Date(sliderValue).toLocaleString()}</div>
                                    <div className={classes.currentSpeed}>{currentSpeed} км/ч</div>
                                </div>}

                                <Slider aria-labelledby="discrete-slider-always" min={minTime} max={maxTime} onChange={onSliderChange} color="secondary" />
                            </div>
                        }
                        <MapContainer
                            whenCreated={mapCreated}
                            center={marker}
                            zoom={10}
                            style={{ minHeight: '300px', height: '50vh', overflow: 'hidden' }}
                        >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapPathLines sliderValue={sliderValue} inputData={checkedPaths} />
                        </MapContainer>
                    </Paper>
                    {speedGraphs.map(item =>
                        <Paper style={{ position: 'relative' }}>
                            <h4>{new Date(item.day).toLocaleDateString()}</h4>
                            <div className={classes.diagramWrapper}>
                                <div className={classes.zoomWrapper}>
                                    <div className={classes.row}>
                                        {[item.maxSpeed, '-', '-', '-', 0].map((item, i) =>
                                            <div key={i}>
                                                <div className={classes.axleY}>{item}</div>
                                                <div className={classes.digramContent}></div>
                                            </div>
                                        )}
                                    </div>
                                    {
                                        <div className={classes.axleX}>
                                            {['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '24:00'].map((item, i) =>
                                                <div key={i} className={classes.xValue}>{item}</div>
                                            )}
                                        </div>
                                    }
                                    <div className={classes.svgDiagram} onScroll={(e) => console.log(1122, e)}>
                                        <svg width="100%" height="100px" className={classes.svg}>
                                            {((item.day + MLSEC_IN_DAY) > sliderValue) && (sliderValue > item.day) && <line
                                                key="current"
                                                x1={(((sliderValue - item.day) * 100) / MLSEC_IN_DAY) + '%'}
                                                y1={'0%'}
                                                x2={(((sliderValue - item.day) * 100) / MLSEC_IN_DAY) + '%'}
                                                y2={'100%'}
                                                stroke="#00FF00"
                                            />}
                                            {item.graph.map((graph: any, index: number) =>
                                                <>
                                                    {(index > 0) && <line
                                                        key={index}
                                                        x1={item.graph[index - 1].x + '%'}
                                                        y1={(100 - ((item.graph[index - 1].speed || 0) * 100) / item.maxSpeed) + '%'}
                                                        x2={item.graph[index].x + '%'}
                                                        y2={(100 - ((item.graph[index].speed || 0) * 100) / item.maxSpeed) + '%'}
                                                        stroke="#F61B44"
                                                    />}
                                                </>
                                            )}
                                        </svg>
                                        {item.photos.map((photo: IEquipmentPhoto, index: number) =>
                                            <Tooltip
                                                title={
                                                    <img alt="Фото" src={`/${photo?.photoIconURL}`} />
                                                }
                                            >
                                                <PhotoCameraIcon
                                                    fontSize="small"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0px',
                                                        left: `calc(${(((photo.timestamp - item.day) * 100) / MLSEC_IN_DAY)}% - 10px)`,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => setSliderValue(photo.timestamp)}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    )}
                    {currentPhoto &&
                        <Paper style={{ position: 'relative' }}>
                            <img alt="Фото" src={fullSize ? `/${currentPhoto?.photoURL}` : `/${currentPhoto?.photoIconURL}`} />
                        </Paper>}
                    <Paper style={{ position: 'relative' }}>
                        <FormControlLabel control={<Checkbox checked={flyToCurrent} onClick={() => setFlyToCurrent(!flyToCurrent)} />} label="Показывать текущую точку на карте" />
                        <FormControlLabel control={<Checkbox checked={fullSize} onClick={() => setFullSize(!fullSize)} />} label="Полный размер фото" />
                    </Paper>
                </div>
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        center: state.map.center,
        currentEquipment: state.userData.currentEquipment,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EquipmetnLocations);