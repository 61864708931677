import {
    AuthState,
    AuthActionTypes,
    SET_AUTH,
    LOGIN, SET_FOUNDED_USERS, SET_CONFIRMED_NICKNAME
} from './types'

const initialState: AuthState = {
    auth: false,
    user: null,
    message: '',
    exactlyUser: '',
    similarUsers: [],
    partners: [],
    confirmedNickname: '',
    nicknameMessage: '',
    equipments: []
};

export function authReducer(
    state = initialState,
    action: AuthActionTypes
): AuthState {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                message: ''
            }
        case SET_AUTH:
            return {
                ...state,
                message: action.message,
                auth: action.auth,
                user: action.user ? { ...action.user } : null,
                partners: action.partners || []
            }
        case SET_FOUNDED_USERS:
            return {
                ...state,
                similarUsers: action.similarUsers || []
            }
        case SET_CONFIRMED_NICKNAME:
            return {
                ...state,
                confirmedNickname: action.name,
                nicknameMessage: action.message,
            }
        default:
            return state
    }
}