import { combineReducers } from 'redux';
import { mapReducer } from './map/reducers'
import { userDataReducer } from './userData/reducers'
import { layoutReducer } from './layout/reducers'
import { authReducer } from './auth/reducers'

export const rootReducer = combineReducers({
    map: mapReducer,
    userData: userDataReducer,
    layout: layoutReducer,
    auth: authReducer
})

export type RootState = ReturnType<typeof rootReducer>