import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from "@material-ui/core";
import { Button } from "@material-ui/core";
import TestPresentation from './TestPresentation';

interface TopToolbarProps {
    auth: boolean;
    onToggle: (v: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
    title: {
        height: '56px',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    topMenu: {
        justifyContent: 'space-between',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end'
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
}));

const TopToolbar: React.FC<TopToolbarProps> = props => {
    const classes = useStyles();

    const title = (
        <img className={classes.title} src="/img/logomid.png" />
    );
    /*<IconButton
                color="secondary"
                aria-label="open drawer"
                edge="start"
                onClick={() => props.onToggle(false)}
                className={classes.menuButton}
            >
                <MenuIcon />
            </IconButton>*/
    return (<Toolbar className={classes.topMenu}>
        <Button
            variant="outlined"
            color="secondary"
            onClick={() => props.onToggle(false)}
            className={classes.menuButton}
        >
            Меню
        </Button>
        {title}
        <TestPresentation />
    </Toolbar >);
};

export default TopToolbar;