import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
    makeStyles,
    Grid
} from "@material-ui/core";
import { IOrder, IEquipment, IIncident, IPriorityEquipment, IPriorityOrder } from '../../store/userData/types';
import { SET_CENTER, MapStates, SET_MAP_STATE } from '../../store/map/types';
import { getRectangle } from '../../store/map/reducers';
import { connect, ConnectedProps } from "react-redux";
import { LatLng } from 'leaflet';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0
    },
    media: {
        height: 200
    },
    card: {
    },
    itemText: {
        marginTop: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical'
    },
    itemTextImage: {
        height: '60px',
        overflow: 'hidden',
    },
    loading: {
        margin: "auto"
    },
    iframeCard: {
        width: '100%'
    },
    navLink: {
        textDecoration: "none"
    },
    title: {
        marginTop: "8px",
        textAlign: "center",
        fontSize: '1.0rem'
    },
    hidden: {
        color: 'red'
    },
    publicView: {
        color: 'blue'
    },
    alert: {
        position: 'absolute'
    },
    content: {
        position: 'relative'
    }
}));

interface CardsProps {
    search?: boolean;
    items: (IEquipment | IOrder | IIncident | IPriorityEquipment | IPriorityOrder)[];
    auth: boolean;
    title: string;
    url: 'equipment' | 'order' | 'incident' | 'priority-equipment' | 'priority-order';
}

const Cards = (props: CardsProps & PropsFromRedux) => {
    const classes = useStyles();
    const [linkId, setLinkId] = useState<string | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState<boolean>(false);
    const [id, setId] = useState<string>('');
    const [item, setItem] = useState<IEquipment | IOrder | IIncident | IPriorityEquipment | IPriorityOrder | null>(null);
    const { items, title, url, setMapState } = props;
    const history = useHistory();
    const location = useLocation();
    const copyLink = (e: any, id: string, token: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(`${window.location.origin.toString()}/public-view/${id}/${token}`);
        setLinkId(id);
    };

    useEffect(() => {
        if (linkId !== null) {
            setTimeout(() => {
                setLinkId(null);
            }, 3000);
        }
    }, [linkId]);

    const onCardClick = (item: IEquipment | IOrder | IIncident | IPriorityEquipment | IPriorityOrder) => {
        if (props.auth) {
            item.coordLat && item.coordLng && props.setCenter(item.coordLat, item.coordLng);
            history.push(url + '/' + item._id);
        } else {
            setItem(item);
        }
    };

    return (
        <>
            {!props.auth && item && <Grid item xs={12} style={{ margin: '8px' }}>
                <Card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt={item.imageUrl ? item.name : 'Нет фото'}
                        image={'/' + (item.imageUrl?.replace('\\', '/') ? item.imageUrl?.replace('\\', '/') : '')}
                        title={item.name}
                    />
                    <CardContent>
                        <Typography variant="h2" component="h2">{item.name}</Typography>
                        <Typography variant="h3" component="h3">{item.phone}</Typography>
                        <Typography variant="body1" component="p">{item.description}</Typography>
                    </CardContent>
                </Card>
            </Grid>}
            <Typography variant="h6" component="h6" className={classes.title}>{title}</Typography>
            <Grid
                className={classes.root}
                container
                direction="row"
                alignItems="center"
                spacing={2}
            >

                {items?.map(item => (
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={() => onCardClick(item)}>
                                <CardMedia
                                    className={classes.media}
                                    image={'/' + (item.icoUrl?.replace('\\', '/') ? item.icoUrl?.replace('\\', '/') : 'img/nofoto.jpg')}
                                    title={item.name}
                                />
                                <CardContent className={classes.content}>
                                    <Typography variant="body2" color="textSecondary" component="p" className={classes.itemText}>
                                        {item.name}
                                    </Typography>
                                    {props.auth && <>
                                        {item.hidden && <h3 className={classes.hidden}>Не опубликовано</h3>}
                                        {item.hasOwnProperty('publicPathLink') &&
                                            <>
                                                {!(item as IEquipment).publicPathLink && <h3 className={classes.publicView}>Нет публичной ссылки</h3>}
                                                {(item as IEquipment).publicPathLink &&
                                                    <Button disabled={!!linkId} onClick={(e) => copyLink(e, item._id || '', (item as IEquipment).publicPathLink)} startIcon={<FileCopyOutlinedIcon />} variant="outlined" color="primary">Ссылка на маршрут</Button>
                                                }
                                            </>
                                        }
                                        {(item._id === linkId) && <Alert className={classes.alert} variant="filled" severity="warning">
                                            Ссылка скопирована в буфер
                                        </Alert>}
                                    </>}
                                    {!props.auth && <>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.itemText}>
                                            {item.phone}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.itemText}>
                                            {item.description}
                                        </Typography>
                                    </>
                                    }
                                </CardContent>
                            </CardActionArea>

                            {!props.search &&
                                <CardActions>
                                    {props.auth && <Button size="small" color="secondary" onClick={() => { setOpenDialog(true); setId(item._id!); setDeleteItem(true); }}>
                                        Удалить
                                    </Button>}
                                </CardActions>
                            }
                            {(props.search || !props.auth) &&
                                <CardActions>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                            setMapState('SHOW_ONE', { ...(item as IEquipment | IOrder) });
                                            item.coordLat && item.coordLng && props.setCenter(item.coordLat!, item.coordLng!); history.push('/map');
                                        }}
                                    >
                                        Показать на карте
                                    </Button>
                                </CardActions>
                            }
                        </Card>
                    </Grid>))}
                {!items && <Typography variant="h4" color="textSecondary" component="h4">Нет данных</Typography>}
            </Grid>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCenter: (lat: number, lng: number) => dispatch({ type: SET_CENTER, center: new LatLng(lat, lng) }),
        setMapState: (state: MapStates, selectedItem: IEquipment | IOrder) => dispatch({ type: SET_MAP_STATE, state, selectedItem })
    };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Cards);
