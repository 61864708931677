import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {connect, ConnectedProps} from "react-redux";
import { GET_PRIORITY_EQUIPMENTS, IPriorityEquipment } from '../../store/userData/types';
import Cards from '../containers/Cards';

const useStyles = makeStyles((theme: any) => ({
    articleImg: {
    }
}));

interface PriorityEquipmentsProps{
}

const PriorityEquipments: React.FC<PriorityEquipmentsProps & PropsFromRedux> = props => {
    const classes = useStyles();
    const { getPriorityEquipments, auth, priorityEquipments } = props;

    useEffect(() => {
        getPriorityEquipments();
    }, []);

    return (
        <Cards auth={auth} items={priorityEquipments} title={'Избранная техника'} url={'priority-equipment'} search={true}/>    
    );
};

interface RootState {
    auth: { auth: boolean };
    userData: { priorityEquipments: IPriorityEquipment[] };
}

const mapStateToProps = (state: RootState) => {
    return {
        priorityEquipments: state.userData.priorityEquipments,
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getPriorityEquipments: () => dispatch({ type: GET_PRIORITY_EQUIPMENTS }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PriorityEquipments);
