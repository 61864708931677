import React, { useEffect, useState, ReactDOM } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../store";
import { IEquipment, IEquipmentCoord, IAllDailyData } from "../../store/userData/types";
import { connect, ConnectedProps } from "react-redux";
import { Polyline, Marker, Popup } from 'react-leaflet';
import { divIcon } from 'leaflet';

const lineOptions = { color: 'red' };

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));

interface Props {
    inputData?: IAllDailyData | null;
    sliderValue: number;
}

const MapPathLines = (props: Props & PropsFromRedux) => {
    const classes = useStyles();
    const currentSlideLocations: {
        equipment: IEquipment;
        location: IEquipmentCoord
    }[] = [];
    const markers: { coords: [number, number], timestamp: number }[] = [];
    const paths = props.inputData ?
        [props.inputData] :
        (props.publicData ?
            [...props.allDailyData, ...props.testPaths, props.publicData] :
            [...props.allDailyData, ...props.testPaths]);
    const polyLines = paths.map(polyLine => {
        if (polyLine.showPath) {
            let time = 0;
            return polyLine.locations.map((location: IEquipmentCoord, index: number) => {
                if (location.timestamp <= props.sliderValue && ((index === (polyLine.locations.length - 1)) || (polyLine.locations[index + 1].timestamp >= props.sliderValue))) {
                    currentSlideLocations.push({
                        equipment: polyLine.equipment,
                        location: location
                    });
                }
                if (polyLine.showTime) {
                    if (time + (polyLine.pathInterval * 1000) < location.timestamp) {
                        time = location.timestamp;
                        markers.push({ coords: location.coords, timestamp: location.timestamp });
                    }
                }
                return location.coords;
            });
        } else {
            return null;
        }
    }).filter(v => v);

    const currentSlideIcon = (date: number, equip: IEquipment) => {
        const d = new Date(date);
        return divIcon({
            html: `<div style="width: 100px;overflow: hidden;background-color: #f50057;padding: 4px;border-radius: 4px;position: absolute;left: -15px;color: white;font-size: 1rem;font-weight: 500;text-transform: uppercase;">
                    ${equip.name}
                    <img style="width: 100%" src="/${equip.icoUrl}" alt="123"/>
                    ${d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()}
                   </div>`
        });
    };

    const getIcon = (date: number) => {
        const icon = document.createElement(`div`);
        icon.className = 'equip-icon';
        icon.style.cssText = 'width: fit-content;background-color: #3f51b5;padding: 4px;border-radius: 4px;position: absolute;left: -15px;color: white;font-size: 0.875rem;font-weight: 500;text-transform: uppercase;';
        const d = new Date(date);
        icon.textContent = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
        return divIcon({
            html: icon
        });
    };

    return (
        <>
            {polyLines && polyLines.length > 0 && polyLines.map(line =>
                line && <Polyline weight={3} pathOptions={lineOptions} positions={line} />
            )}
            {markers.map((item, index) =>
                <Marker
                    key={index}
                    position={item.coords}
                    title={new Date(item.timestamp).toLocaleString()}
                    icon={getIcon(item.timestamp)}
                >
                </Marker>
            )
            }
            {currentSlideLocations.map((item, index) =>
                <Marker
                    key={index}
                    position={item.location.coords}
                    title={new Date(item.location.timestamp).toLocaleString()}
                    icon={currentSlideIcon(item.location.timestamp, item.equipment)}
                >
                </Marker>
            )
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        allDailyData: state.userData.allDailyData,
        publicData: state.userData.publicData,
        testPaths: state.userData.testPaths
    };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MapPathLines);
