import React, { useEffect, useState } from 'react';
import {
    makeStyles
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import { RootState } from "../../store";
import { GET_AVTOPARK, IEquipment, IOrder } from '../../store/userData/types';
import { PinDropSharp } from '@material-ui/icons';
import Cards from '../containers/Cards';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0
    }
}));

const Avtopark = (props: PropsFromRedux) => {
    const classes = useStyles();
    const { name } = useParams<{ name: string }>();
    const [avtopark, setAvtopark] = useState(props.avtopark);

    useEffect(() => { props.getAvtopark(name) }, []);
    useEffect(() => { setAvtopark(props.avtopark) }, [props.avtopark]);

    return (
        <>
            <h1>{avtopark?.user?.name || name}</h1>
            {avtopark && <>
                {avtopark.equipments && (avtopark.equipments.length > 0) && <Cards auth={false} items={avtopark.equipments} title={'Техника пользователя'} url={'equipment'} />}
                {avtopark.works && (avtopark.works.length > 0) && <Cards auth={false} items={avtopark.works} title={'Заказы пользователя'} url={'order'} />}
                <h3>Ближайшие города</h3>
                <p>{avtopark.h1}</p>
            </>}
        </>
    );
};


const mapStateToProps = (state: RootState) => {
    return {
        avtopark: state.userData.avtopark,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getAvtopark: (name: string) => dispatch({ type: GET_AVTOPARK, name })
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Avtopark);