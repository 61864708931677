export const GET_EQUIPMENTS = 'GET_EQUIPMENTS';
export const SET_EQUIPMENTS = 'SET_EQUIPMENTS';
export const GET_PRIORITY_EQUIPMENTS = 'GET_PRIORITY_EQUIPMENTS';
export const SET_PRIORITY_EQUIPMENTS = 'SET_PRIORITY_EQUIPMENTS';
export const GET_INCIDENTS = 'GET_INCIDENTS';
export const SET_INCIDENTS = 'SET_INCIDENTS';
export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const GET_PRIORITY_ORDERS = 'GET_PRIORITY_ORDERS';
export const SET_PRIORITY_ORDERS = 'SET_PRIORITY_ORDERS';
export const GET_EQUIPMENT = 'GET_EQUIPMENT';
export const POST_EQUIPMENT = 'POST_EQUIPMENT';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const SET_EQUIPMENT = 'SET_EQUIPMENT';
export const GET_PRIORITY_EQUIPMENT = 'GET_PRIORITY_EQUIPMENT';
export const SET_PRIORITY_EQUIPMENT = 'SET_PRIORITY_EQUIPMENT';
export const GET_INCIDENT = 'GET_INCIDENT';
export const SET_INCIDENT = 'SET_INCIDENT';
export const GET_ORDER = 'GET_ORDER';
export const POST_ORDER = 'POST_ORDER';
export const SET_ORDER = 'SET_ORDER';
export const GET_PRIORITY_ORDER = 'GET_PRIORITY_ORDER';
export const SET_PRIORITY_ORDER = 'SET_PRIORITY_ORDER';
export const SET_DAILY_LOCATION = 'SET_DAILY_LOCATION';
export const GET_DAILY_LOCATION = 'GET_DAILY_LOCATION';
export const GET_DAILY_PHOTOS = 'GET_DAILY_PHOTOS';
export const SET_DAILY_PHOTOS = 'SET_DAILY_PHOTOS';
export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const SET_SHOW_PHOTO = 'SET_SHOW_PHOTO';
export const SET_SHOW_PATH = 'SET_SHOW_PATH';
export const SET_TIME_PATH = 'SET_TIME_PATH';
export const SET_PATH_INTERVAL = 'SET_PATH_INTERVAL';
export const GET_DAILY_DATA = 'GET_DAILY_DATA';
export const SET_DAILY_DATA = 'SET_DAILY_DATA';
export const GET_PUBLIC_PATH = 'GET_PUBLIC_PATH';
export const SET_PUBLIC_PATH = 'SET_PUBLIC_PATH';
export const GET_AVTOPARK = 'GET_AVTOPARK';
export const SET_AVTOPARK = 'SET_AVTOPARK';
export const SET_TEST_PATHS = 'SET_TEST_PATHS';
export const SET_TEST_MONITORING = 'SET_TEST_MONITORING';

export interface SetAvtopark {
    type: typeof SET_AVTOPARK
    data: any;
}

export interface GetAvtopark {
    type: typeof GET_AVTOPARK
    name: string;
}

export interface SetPublicPath {
    type: typeof SET_PUBLIC_PATH
    data: IAllDailyData;
}
export interface SetShowPhoto {
    type: typeof SET_SHOW_PHOTO
    equipmentId: string;
    showPhoto: boolean;
}
export interface GetPublicPath {
    type: typeof GET_PUBLIC_PATH
    id: string;
    token: string;
}

export interface SetShowPath {
    type: typeof SET_SHOW_PATH
    equipmentId: string;
    showPath: boolean;
}

export interface SetTimePath {
    type: typeof SET_TIME_PATH
    equipmentId: string;
    showTime: boolean;
}

export interface SetPathInterval {
    type: typeof SET_PATH_INTERVAL
    equipmentId: string;
    pathInterval: number;
}

export interface PostEquipment {
    type: typeof POST_EQUIPMENT
    equipment: IEquipmentForm
}

export interface PostOrder {
    type: typeof POST_ORDER
    order: IOrderForm
}

export interface AddFavorite {
    type: typeof ADD_TO_FAVORITE
    id: string
    typeFav: number
}

export interface IPreview {
    _id: string;
    text: string;
    description: string;
    title: string;
    icoUrl: string;
    coordLat: number;
    coordLng: number;
}

export interface IDailyPhoto {
    equipmentId: string;
    equipment: IEquipment;
    photos: IEquipmentPhoto[];
}

export interface IAllDailyData {
    equipmentId: string;
    equipment: IEquipment;
    locations: IEquipmentCoord[];
    photos: IEquipmentPhoto[];
    showPhoto: boolean;
    showPath: boolean;
    showTime: boolean;
    pathInterval: number;
}
export interface IDailyLocation {
    equipmentId: string;
    equipment: IEquipment;
    locations: IEquipmentCoord[];
}

export interface IEquipmentPhoto {
    timestamp: number;
    photoURL: string;
    photoIconURL: string;
}

export interface IEquipmentCoord {
    timestamp: number;
    coords: [number, number];
    speed?: number;
}

export interface IEquipmentForm {
    _id?: string;
    hidden: boolean;
    type: string;
    name: string;
    description: string;
    publicPathLink: string;
    coordLat?: number;
    coordLng?: number;
    phone: string;
    price?: number | null;
    image: File | null;
    works: string[] | string;
}

export interface IEquipment {
    _id?: string;
    hidden: boolean;
    publicPathLink: string;
    type: string;
    name: string;
    phone: string;
    description: string;
    coordLat?: number;
    coordLng?: number;
    price?: number;
    icoUrl: string;
    imageUrl: string;
    userUrl: string;
    works: string[];
}

export interface IOrderForm {
    _id?: string;
    hidden: boolean;
    type: string;
    name: string;
    description: string;
    coordLat?: number;
    coordLng?: number;
    phone: string;
    image: File | null;
    equips: string[] | string;
}

export interface IOrder {
    _id?: string;
    hidden: boolean;
    type: string;
    name: string;
    phone: string;
    description: string;
    coordLat?: number;
    coordLng?: number;
    icoUrl: string;
    imageUrl: string;
    userUrl: string;
    equips: string[];
}

export interface IPriorityEquipment {
    _id?: string;
    hidden: boolean;
    type: string;
    description: string;
    phone: string;
    name: string;
    icoUrl: string;
    imageUrl: string;
    coordLat: number;
    coordLng: number;
}

export interface IPriorityOrder {
    _id?: string;
    hidden: boolean;
    type: string;
    name: string;
    description: string;
    phone: string;
    icoUrl: string;
    imageUrl: string;
    coordLat: number;
    coordLng: number;
}

export interface IIncident {
    _id?: string;
    hidden: boolean;
    type: string;
    name: string;
    phone: string;
    description: string;
    coordLat?: number;
    coordLng?: number;
    icoUrl: string;
    imageUrl: string;
}

export interface UserDataState {
    currentEquipment: IEquipment | null;
    currentOrder: IOrder | null;
    currentPriorityEquipment: IPriorityEquipment | null;
    currentPriorityOrder: IPriorityOrder | null;
    currentIncident: IIncident | null;
    equipments: IEquipment[];
    priorityEquipments: IEquipment[];
    orders: IOrder[];
    priorityOrders: IOrder[];
    incidents: IPreview[];
    allDailyData: IAllDailyData[];
    publicData: IAllDailyData | null;
    avtopark: any;
    minTime: number | null;
    maxTime: number | null;
    testPaths: IAllDailyData[],
    testMonitoring: IDailyLocation | null
}

export interface GetData {
    type: 'GET_EQUIPMENTS' | 'GET_ORDERS' | 'GET_PRIORITY_ORDERS' | 'GET_PRIORITY_EQUIPMENTS' | 'GET_INCIDENTS';
}

export interface GetDataId {
    setType: 'SET_EQUIPMENT' | 'SET_ORDER';
    getType: 'GET_EQUIPMENT' | 'GET_ORDER';
    type: 'equipment' | 'order';
    id: string;
}

export interface SetDailyData {
    type: typeof SET_DAILY_DATA;
    allDailyData: IAllDailyData[];
}

export interface GetDailyData {
    type: typeof GET_DAILY_DATA;
}

export interface SetEquipments {
    type: typeof SET_EQUIPMENTS;
    equipments: IEquipment[];
}

export interface SetOrder {
    type: typeof SET_ORDER;
    order: IOrder;
}

export interface SetEquipment {
    type: typeof SET_EQUIPMENT;
    equipment: IEquipment;
}

export interface SetPriorityEquipments {
    type: typeof SET_PRIORITY_EQUIPMENTS;
    priorityEquipments: IEquipment[];
}

export interface SetIncidents {
    type: typeof SET_INCIDENTS;
    incidents: IPreview[];
}

export interface SetOrders {
    type: typeof SET_ORDERS;
    orders: IOrder[];
}

export interface SetPriorityOrders {
    type: typeof SET_PRIORITY_ORDERS;
    priorityOrders: IOrder[];
}
export interface SetTestPaths {
    type: typeof SET_TEST_PATHS;
    testPaths: IAllDailyData[];
}
export interface SetTestMonitoring {
    type: typeof SET_TEST_MONITORING;
    testMonitoring: IDailyLocation | null;
}

export type UserDataActionTypes = SetDailyData | SetShowPhoto | SetEquipments | SetEquipment | SetPriorityOrders |
    SetOrders | SetIncidents | SetPriorityEquipments | SetOrder | SetShowPath | SetPathInterval | SetTimePath | SetPublicPath |
    SetAvtopark | SetTestPaths | SetTestMonitoring;