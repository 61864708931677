import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { Link, NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '960px',
        margin: 'auto',
        padding: '8px'
    },
    video: {
        maxWidth: "280px",
        width: "100%",
        margin: '0 auto 8px',
        height: "200px"
    },
    navLink: {
        margin: '8px',
        textDecoration: 'none',
        color: 'black'
    },
    button: {
        margin: '8px'
    },
    img: {
        width: '100%',
        maxWidth: '600px'
    },
    p: {
        textAlign: 'justify'
    }
}));

const Welcome = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <h1>Аренда и продажа спецтехники.</h1>
            <h2><b style={{ color: 'red' }}>Бесплатный</b> GPS и фотомониторинг любых объектов на основе Android приложения.</h2>
            <p className={classes.p}>Для получения информации о наличии техники и заказов в регионе:</p>
            <ul style={{ listStyleType: 'none' }}>
                <li style={{ textAlign: 'start', marginTop: '8px' }}><b>Открыть страницу Карта</b> </li>
                <li style={{ textAlign: 'start', marginTop: '8px' }}><b>Выбрать область на карте</b> </li>
                <li style={{ textAlign: 'start', marginTop: '8px' }}><b>Настроить фильтр поиска по своим параметрам</b> </li>
            </ul>
            <p className={classes.p}>Для регистрации в системе нужна только <b>электронная почта</b>.
                После регистрации вы сможете занести личную спецтехнику и заказы в систему(<b style={{ color: 'red' }}>любое количество объектов - Бесплатно</b>).</p>
            <img className={classes.img} src="/img/Site3.png" alt="Поиск спецтехники и заказов" />

            <h3>GPS/фото мониторинг транспорта</h3>
            <p className={classes.p}>Для мониторинга собственных объектов необходимо установить Android-приложение на телефон. Установить приложение вы можете,
                скачав приложение из главного меню сайта, нажав на кнопку "Доступно в Google-play", либо напрямую через
                Google-play(поисковая фраза: "mazuta pro").
            </p>
            <p className={classes.p}>Выполнив указанные действия Вы имеете возможность явно или скрытно установить телефон,
                с запущенным приложением, на спецтехнику(или другой объект) и конролировать маршрут движения объектов и скорость в реальном времени.</p>
            <h3>Ознакомьтесь с краткой Презентацией сервиса на Youtube:</h3>
            <div>
                <iframe className={classes.video} src="https://www.youtube.com/embed/clKRKi1k15k" title="YouTube video player" frameBorder='0' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <p className={classes.p}>Кроме этого вы имеете возможность контролировать скорость выполняемых работ при запуске
                в Android-приложении режима периодического фотографирования. Для этого, необходимо направить камеру телефона
                на контролируемый участок работы,например, на лобовое стекло экскаватора, самосвала, сельхозтехники и так далее.
                Также в приложении можно фотографировать и отправлять фотографии в ручном режиме, для того случая если, для
                отчетности, фотографию необходимо сделать в конкретный момент времени.
            </p>
            <img className={classes.img} src="/img/Site.png" alt="GPS/фото мониторинг транспорта" />
            <img className={classes.img} style={{ maxHeight: '400px', width: 'auto' }} src="/img/appFoto.jpg" alt="GPS/фото мониторинг транспорта" />
            <p className={classes.p}>Важно отметить, что приложение Mazuta Pro работает на телефоне и в фоновом режиме,
                при выключенном экране, и зря не расходует питание батареи, т.е. наше приложение при необходимости можно
                продолжительно время использовать без подзарядки телефона.
            </p>
            <p className={classes.p}>Во время работы функции контроля местоположения и фотографирования, полученные
                данные можно увидеть
                на главной карте сервиса Mazuta Pro нажав на кнопку "Записи".</p>
            <img className={classes.img} src="/img/cabinet.jpg" alt="GPS/фото мониторинг транспорта" />
            <p className={classes.p}>Кроме этого есть возможность сделать эти данные публичными, если зайти на страницу
                техники в личном кабинете и установить флаг "Публичный маршрут". После этого, пройдя на общую страницу автопарка,
                необходимо нажать на кнопку "Ссылка на маршрут".
                После этого в буфере обмена будет храниться адрес, который вы можете отправить вашему
                заказчику через Телеграмм, Ватсапп или другой мессенджер. Данная функция будет полезна для удобства встречи
                заказчика и исполнителя на месте, допустим если вы работаете на манипуляторе или трале и доставляете тот или
                иной груз за город. Заказчик будет знать где вы находитесь в конкретный момент времени.
                Или допустим если вы отправляете ценный груз, для этого вы можете положить телефон с запущенным приложением
                как GPS-маяк и контролировать местоположение груза.
            </p>
            <p className={classes.p}>Стоит отметить, что на главном экране при нажатии на кнопку Записи вы сможете увидеть
                всю свою технику работающую на линии на данный момент.</p>
            <p className={classes.p}>Для анализа дневных маршрутов, в нижней части экрана отображается удобный бегунок,
                устанавливающий время для которого нам наобходимо отобразить местоположения всей техники на линии.</p>
            <p className={classes.p}> После того как вы завершили рабочий день или неделю вы можете отправить полученную
                запись в архив,
                нажав на кнопку "Отправить дневную запись в архив" и все текущие записи будут разделены по рабочим дням и
                будут доступны в личном кабинете на странице техники во вкладке Контроль.
                Зайдя на данную страницу мы видим таблицу со столбцами даты, пробега в километрах за день, количества
                сделанных фотографий и GPS-точек на маршруте. После выбора конретных дней, маршруты на выбранные дни отобразятся на карте, кроме этого отобразятся
                графики скорости для каждого дня. Также после перемещения бегунка внизу карты, мы получим текущую фотографию
                с техники и значение скорости в конкретный момент времени.</p>
            <p className={classes.p}>
                Пройдите Регистрацию:
                <Link to={'/signup'} className={classes.navLink}>
                    <Button variant="contained" color="secondary">Регистрация</Button>
                </Link>
            </p>
            <p className={classes.p}>
                Форум:
                <Link to={'/forum/main'} className={classes.navLink}>
                    <Button variant="contained" color="primary">Форум</Button>
                </Link>
            </p>
            <p className={classes.p}>
                Для связи и предложений: <b>info@mazuta.pro</b>
            </p>
        </Box >
    );
};

export default Welcome;