import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    makeStyles,
    TextField
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { SEARCH_USER_NAME, ADD_PARTNER, SAVE_PARTNERS_PERMISSIONS, IPartner, CHECK_NICKNAME, SET_NICKNAME } from '../../store/auth/types';
import { GET_EQUIPMENTS } from '../../store/userData/types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    buttons: {
        margin: 'auto',
    },
    permButtons: {
        margin: '8px'
    },
    autocomplete: {
        margin: '8px auto'
    },
    form: {
        margin: '16px'
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        maxWidth: "100px",
        padding: '4px',
        overflow: 'hidden'
    },
    tableIco: {
        maxWidth: "100px",
        maxHeight: "30px"
    },
    tableEquipName: {
        textAlign: 'center',
        textOverflow: 'ellipsis'
    },
    tableIcoWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    tableWrapper: {
        maxWidth: '100%',
        overflowX: 'auto'
    },
    partnerName: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

interface Props {
    equipmentId: string
}

const Settings = (props: Props & PropsFromRedux) => {
    const classes = useStyles();
    const { postNickName, userSearch, similarUsers, addPartner, partners, equipments, getEquipments, savePartnerPermissions, user, nicknameMessage, confirmedNickname, checkNickname } = props;
    const [value, setValue] = useState<string | null>(null);
    const [nickName, setNickName] = useState<string>(user?.name || '');
    const [nickNameError, setNickNameError] = useState<string>(((nickName.length > 3) && "Минимальная длина 4 символа") || '');
    const [nicknameInfo, setNicknameInfo] = useState<string>('');
    const [localPartners, setLocalPartners] = useState(partners.map(v => ({ ...v })));
    const [saveButton, setSaveButton] = useState(false);

    useEffect(() => {
        if (nicknameMessage) {
            if (user && (nickName === user.name)) {
                setNicknameInfo('Это ваш текущий никнейм');
            } else {
                setNicknameInfo(nicknameMessage);
            }
        }
        else {
            if (confirmedNickname) {
                setNicknameInfo(`Никнейм ${confirmedNickname} подтверждён`);
            }
        }
    }, [nicknameMessage, confirmedNickname]);
    useEffect(() => { setNickName(user?.name || '') }, [user]);
    useEffect(() => { getEquipments() }, []);
    useEffect(() => { setLocalPartners(partners.map(v => ({ ...v }))); }, [partners]);

    const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const name = event.target.value as string;
        if (name && (name.length > 1)) {
            userSearch(name);
        }
    };

    const onPermissionChange = (event: React.ChangeEvent<HTMLInputElement>, id: string, partnerId: string) => {
        setLocalPartners(localPartners.map(partner => {
            if (partner.id === partnerId) {
                partner.permissions = partner.permissions.filter(v => v !== id);
                if (event.target.checked) {
                    partner.permissions = partner.permissions.concat(id);
                }
            }
            return partner;
        }));
        setSaveButton(true);
    };

    const onSelect = (event: any) => {
        const value = event.target.value as string;
        setValue(value);
    };

    const onSave = () => {
        setSaveButton(false);
        savePartnerPermissions(localPartners);
    };

    const onCancel = () => {
        setSaveButton(false);
        setLocalPartners(partners.map(v => ({ ...v })));
    };

    const onDeletePartner = (id: string) => {
        setSaveButton(true);
        setLocalPartners(localPartners.filter(v => v.id !== id));
    };

    const onNicknameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const name = event.target.value as string;
        setNickName(name || '');
        if (name && name.length > 3) {
            setNickNameError("");
            checkNickname(name);
        } else {
            setNickNameError("Минимальная длина 4 символа");
        }
    };

    return (
        <div className={classes.root}>
            <h3>Никнейм на сервисе Mazuta.pro </h3>
            <div className={classes.form}>
                <div style={{ marginBottom: '8px' }}>
                    <TextField id="outlined-basic" label="Никнейм" variant="outlined" onChange={onNicknameChange} helperText={nickNameError} error={!!nickNameError} value={nickName} />
                </div>
                {nicknameInfo && (<p>{nicknameInfo}</p>)}
                <Button className={classes.buttons} onClick={() => { postNickName(confirmedNickname) }} variant="outlined" color="primary" disabled={!confirmedNickname}>Установить никнейм</Button>
            </div>
            <h3>Добавление партнеров</h3>
            <div className={classes.form}>
                <Autocomplete
                    className={classes.autocomplete}
                    id="combo-box-demo"
                    options={similarUsers}
                    getOptionLabel={(option) => option}
                    style={{ width: 300 }}
                    onSelect={onSelect}
                    noOptionsText="-"
                    renderInput={(params) => <TextField {...params} label="Имя пользователя" variant="outlined" onChange={onChange} />}
                />
                <Button className={classes.buttons} onClick={() => { value && addPartner(value); setValue(null) }} variant="outlined" color="primary" disabled={!similarUsers.find(v => v === value)}>Пригласить пользователя в команду</Button>
            </div>

            <h3>Настройка разрешений для партнеров</h3>
            <div>
                <Button className={classes.permButtons} onClick={onSave} variant="outlined" color="secondary" disabled={!saveButton}>Сохранить изменения</Button>
                <Button className={classes.permButtons} onClick={onCancel} variant="outlined" color="primary" disabled={!saveButton}>Отмена</Button>
            </div>
            {equipments &&
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Партнер</TableCell>
                                {equipments.map(equip =>
                                    <TableCell>
                                        <div className={classes.tableHead}>
                                            {equip.icoUrl && <div className={classes.tableIcoWrapper}><img className={classes.tableIco} src={equip.icoUrl} /></div>}
                                            <div className={classes.tableEquipName}>{equip.name}</div>
                                        </div>
                                    </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {localPartners?.map((partner: any) => (
                                <TableRow key={partner.id}>
                                    <TableCell className={classes.partnerName}>
                                        <span>{partner.name}</span>
                                        <IconButton color="secondary" size="medium" component="span" onClick={() => onDeletePartner(partner.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    {equipments?.map(equip =>
                                        <TableCell align='center'>
                                            <Switch checked={!!partner.permissions.find((v: string) => v === equip._id)} onChange={(e) => { onPermissionChange(e, equip?._id || '', partner.id) }} />
                                        </TableCell>)
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            }
            <div>
                <Button className={classes.permButtons} onClick={onSave} variant="outlined" color="secondary" disabled={!saveButton}>Сохранить изменения</Button>
                <Button className={classes.permButtons} onClick={onCancel} variant="outlined" color="primary" disabled={!saveButton}>Отмена</Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        user: state.auth.user,
        usersInCommand: state.auth.user,
        similarUsers: state.auth.similarUsers,
        partners: state.auth.partners,
        equipments: state.userData.equipments,
        confirmedNickname: state.auth.confirmedNickname,
        nicknameMessage: state.auth.nicknameMessage,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        userSearch: (name: string) => dispatch({ type: SEARCH_USER_NAME, name }),
        addPartner: (name: string) => dispatch({ type: ADD_PARTNER, name }),
        savePartnerPermissions: (partners: IPartner[]) => dispatch({ type: SAVE_PARTNERS_PERMISSIONS, partners }),
        getEquipments: () => dispatch({ type: GET_EQUIPMENTS }),
        checkNickname: (name: string) => dispatch({ type: CHECK_NICKNAME, name }),
        postNickName: (name: string) => dispatch({ type: SET_NICKNAME, name }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Settings);