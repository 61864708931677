import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga";
import { Provider } from 'react-redux';
import {rootReducer} from './store';
import layoutSaga from "./store/layout/sagas";
import mapSaga from "./store/map/sagas";
import userDataSaga from "./store/userData/sagas";
import authSaga from "./store/auth/sagas";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware)
);

[
    mapSaga,
    userDataSaga,
    authSaga,
    layoutSaga
].map(sagaMiddleware.run);

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

