export const GET_TYPES = 'GET_TYPES';
export const SET_TYPES = 'SET_TYPES';

export interface IType {
    _id: string;
    value: string;
    name: string;
    seoText: string;
    h3: string;
}

export interface LayoutState {
    works: IType[],
    equipments: IType[]
}

export interface SetTypes {
    type: typeof SET_TYPES,
    works: IType[],
    equipments: IType[]
}

export interface GetTypes {
    type: typeof GET_TYPES,
    urlType: string
}

export type LayoutActionTypes = SetTypes | GetTypes;