import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { RootState } from "../../store";
import { IOrder, IEquipment, IIncident, IPriorityEquipment, IPriorityOrder } from '../../store/userData/types';
import { SET_CENTER, MapStates, SET_MAP_STATE } from '../../store/map/types';
import { getRectangle } from '../../store/map/reducers';
import { LatLng } from 'leaflet';

const useStyles = makeStyles((theme: any) => ({
    tableWrapper: {
        maxWidth: '100%'
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        maxHeight: '170px',
        overflowY: 'auto'
    },
    tableImg: {
        maxHeight: '100px'
    }
}));

const AllEquipments: React.FC<PropsFromRedux> = props => {
    const classes = useStyles();
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<any>([]);
    const history = useHistory();
    const { setMapState } = props;

    useEffect(() => {
        fetch(`/api/get-all-equipments?offset=${rowsPerPage * page}&limit=${rowsPerPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(response => response.json()).then(response => { setRows(response.equipments); setCount(response.count); })
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <div className={classes.tableWrapper}>
                <Paper>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Изображение</TableCell>
                                    <TableCell align="center">Название</TableCell>
                                    <TableCell align="center">Описание</TableCell>
                                    <TableCell align="center">Телефон</TableCell>
                                    <TableCell align="center">Дата</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((item: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell width={50} align="center">
                                            {item.icoUrl && <img className={classes.tableImg} src={item.icoUrl} />}
                                            {item.coordLat && item.coordLng && <Button
                                                size="small"
                                                style={{ fontSize: '8px', fontWeight: 1000 }}
                                                color="secondary"
                                                onClick={() => {
                                                    setMapState('SHOW_ONE', { ...(item as IEquipment | IOrder) });
                                                    item.coordLat && item.coordLng && props.setCenter(item.coordLat!, item.coordLng!); history.push('/map');
                                                }}
                                            >
                                                Показать на карте
                                            </Button>}
                                            <Button style={{ fontSize: '10px', fontWeight: 1000 }} size="small" color="primary" onClick={() => { history.push('/user/' + item.userUrl) }}>Весь Автопарк</Button>
                                        </TableCell>
                                        <TableCell width={50}><div className={classes.tableCell}>{item.name}</div></TableCell>
                                        <TableCell ><div className={classes.tableCell}>{item.description}</div></TableCell>
                                        <TableCell width={50} align="center"><div className={classes.tableCell}>{item.phone}</div></TableCell>
                                        <TableCell width={50} align="center"><div className={classes.tableCell}>{new Date(item.createdAt || 1628111167112).toLocaleDateString()}</div></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        orders: state.userData.orders,
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCenter: (lat: number, lng: number) => dispatch({ type: SET_CENTER, center: new LatLng(lat, lng), rectangle: getRectangle(new LatLng(lat, lng), 1) }),
        setMapState: (state: MapStates, selectedItem: IEquipment | IOrder) => dispatch({ type: SET_MAP_STATE, state, selectedItem })
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AllEquipments);
