import React, { FormEvent, useEffect } from 'react';
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import { useHistory } from "react-router-dom";
import { LOGIN } from '../../../store/auth/types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        form: {
            margin: 'auto',
            maxWidth: '280px',
            marginBottom: '16px'
        },
        button: {
            display: 'block',
            margin: 'auto'
        },
        info: {
            maxWidth: '960px',
            margin: 'auto',
            padding: '8px',
            textAlign: 'left'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center'
        },
        video: {
            maxWidth: "280px",
            width: "100%",
            margin: '0 auto 8px',
            height: "200px"
        },
        textField: {
            margin: theme.spacing(1),
        },
    }));


interface LoginProps {
}

const Login = (props: LoginProps & PropsFromRedux) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [showPassword, setShowPassword] = React.useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.auth) {
            history.push('/equipments');
        }
    }, [props.auth, props.message]);

    const sendLogin = (e: FormEvent) => {
        e.preventDefault();
        props.onLogin(email, password);
    };
    return (
        <>
            <form className={classes.form} onSubmit={(e) => sendLogin(e)}>
                <Typography variant="h4" component="h2">Вход</Typography>
                <div>
                    <TextField fullWidth className={classes.textField} label="Email (Электронная почта)" variant="outlined" onChange={(e) => setEmail((e.target.value as string).trim())} />
                </div>
                <div>
                    <FormControl fullWidth className={classes.textField} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            label="Пароль"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value as string)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                </div>
                <Button type='submit' className={classes.button} variant="contained" color="secondary">Вход</Button>
                <Typography variant="h6" component="h6">{props.message}</Typography>
            </form>
            <div>
                <Button className={classes.button} variant="contained" color="primary" onClick={() => history.push('/signup')}>Регистрация</Button>
            </div>
            <div style={{ marginTop: '8px' }}>
                <Button className={classes.button} color="primary" onClick={() => history.push('/reset-password')}>Забыли пароль?</Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        auth: state.auth.auth,
        message: state.auth.message
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLogin: (email: string, password: string) => dispatch({ type: LOGIN, email, password }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Login);
