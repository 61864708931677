import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    onClose: (type: number, text: string, result: boolean) => void;
    open: boolean;
    type: number;
    parentId: string;
};

export default function DialogForText(props: Props) {
    const { onClose, open, type, parentId } = props;
    const [text, setText] = useState("");
    const handleClose = (result: boolean) => {
        if (result && text) {
            fetch('/forum-api/' + ((type === 0) ? 'create-thread' : ((type === 2) ? 'create-message' : 'create-theme')), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({ body: text, parentId })
            })
        }

        onClose(type, text, result);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Создать {type ? "сообщение" : "тему"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите текст
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={text}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => setText(e.target.value || '')}
                        label="Текст"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary" disabled={!text}>
                        Создать
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}