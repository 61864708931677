import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {connect, ConnectedProps} from "react-redux";
import { GET_INCIDENTS, IIncident } from '../../store/userData/types';
import Cards from '../containers/Cards';

const useStyles = makeStyles((theme: any) => ({
    articleImg: {
    }
}));

interface IncidentsProps{
}

const Incidents: React.FC<IncidentsProps & PropsFromRedux> = props => {
    const classes = useStyles();
    const { getIncidents, auth, incidents } = props;

    useEffect(() => {
        getIncidents();
    }, []);

    return (
        <Cards auth={auth} items={incidents} title={'Происшествия'} url={'incident'}/>    
    );
};

interface RootState {
    auth: { auth: boolean };
    userData: { incidents: IIncident[] };
}

const mapStateToProps = (state: RootState) => {
    return {
        incidents: state.userData.incidents,
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getIncidents: () => dispatch({ type: GET_INCIDENTS }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Incidents);
