import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center'
    }
}));

const Page404: React.FC = () => {
    const classes = useStyles();
    return (
        <>
           <h2 className={classes.root}>Страница не найдена</h2>
        </>
    );
};

export default Page404;
