import React from 'react';
import {
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0
    }
}));

const Contacts = () => {
    const classes = useStyles();

    return (
        <>
            <>
                <h3>Для связи и предложений - info@mazuta.pro</h3>
            </>
        </>
    );
};

export default Contacts;