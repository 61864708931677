import React from 'react';
import './App.css';
import Layout from './hoc/Layout';
import Equipments from './components/equipments/Equipments';
import Equipment from './components/equipments/Equipment';
import OrderInformation from './components/orders/OrderInformation';
import Orders from './components/orders/Orders';
import PriorityEquipments from './components/priorityEquipments/PriorityEquipments';
import PriorityOrders from './components/priorityOrders/PriorityOrders';
import Incidents from './components/incidents/Incidents';
import Settings from './components/settings/Settings';
import Instruction from './components/instruction/Instruction';
import PublicView from './components/publicView/PublicView';
import Contacts from './components/contacts/Contacts';
import Search from './components/search/Search';
import DailyDetails from './components/dailyData/DailyDetails';
import Page404 from "./hoc/Page404";
import Map from "./components/containers/Map";
import Login from './components/auth/Login/Login';
import Signup from './components/auth/Signup/Signup';
import Confidential from './components/confidential/Confidential';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Avtopark from './components/avtopark/Avtopark';
import Forum from './components/forum/Forum';
import AllEquipments from './components/allEquipments/AllEquipments';
import AllOrders from './components/allOrders/AllOrders';
import ResetPassword from './components/auth/Login/ResetPassword';
import NewPassword from './components/auth/Login/NewPassword';
import Welcome from './components/containers/Welcome';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' >
          <div className="App">
            <Layout>
              <Switch>
                <Route exact path='/reset/:email/:token' component={NewPassword} />
                <Route exact path='/reset-password' component={ResetPassword} />
                <Route exact path='/signup' component={Signup} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/equipments' component={Equipments} />
                <Route exact path='/equipment/:id' component={Equipment} />
                <Route exact path='/order/:id' component={OrderInformation} />
                <Route exact path='/orders' component={Orders} />
                <Route exact path='/priority-equipments' component={PriorityEquipments} />
                <Route exact path='/priority-orders' component={PriorityOrders} />
                <Route exact path='/incidents' component={Incidents} />
                <Route exact path='/settings' component={Settings} />
                <Route exact path='/instruction' component={Instruction} />
                <Route exact path='/search' component={Search} />
                <Route exact path='/contacts' component={Contacts} />
                <Route exact path='/daily/:id' component={DailyDetails} />
                <Route exact path='/user/:name' component={Avtopark} />
                <Route exact path='/public-view/:id/:token' component={PublicView} />
                <Route exact path='/forum/:id' component={Forum} />
                <Route exact path='/confidential' component={Confidential} />
                <Route exact path='/all-equipments' component={AllEquipments} />
                <Route exact path='/all-orders' component={AllOrders} />
                <Route exact path='/' component={() => <Welcome />} />
                <Route exact path='/adv' component={() => <Welcome />} />
                <Route exact path='/map' component={() => <Map layoutPage={true} height={'calc(-64px + 100vh)'} />} />
                <Route exact path='/arenda/:type' component={() => <Map layoutPage={true} height={'calc(-64px + 100vh)'} />} />
                <Route exact path='/poisk-raboty/:type' component={() => <Map layoutPage={true} height={'calc(-64px + 100vh)'} />} />
                <Route component={Page404} />
              </Switch>
            </Layout>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
