import { useState } from 'react';
import { makeStyles, Select, MenuItem, Slider, Button, Grid } from "@material-ui/core";
import { SEARCH_EQUIPMENT, SET_KLM, SearchType } from '../../store/map/types';
import { connect, ConnectedProps } from "react-redux";
import { RootState } from '../../store';
import { LatLngTuple } from "leaflet";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    select: {
        display: 'block',
        margin: 'auto',
        minWidth: '100px'
    },
    formControl: {
        width: '100%',
        margin: '8px 0'
    },
    h4: {
        marginBottom: '0px'
    },
    slider: {
        '& .MuiSlider-valueLabel': {
            top: '-10px'
        }
    }
}));

interface SearchEquipmentsProps {
}

const SearchEquipments = (props: SearchEquipmentsProps & PropsFromRedux) => {
    const classes = useStyles();
    const [type, setType] = useState<SearchType>('EQUIPMENT');
    const [typeItem, setTypeItem] = useState("");
    const [klm, setKlm] = useState(props.klm);
    const { workTypes, equipmentTypes } = props;

    const typeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as SearchType);
    };

    const klmChange = (event: any, newValue: number | number[]) => {
        setKlm(newValue as number);
        props.setKlm(newValue as number);
    };

    const typeItemsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTypeItem(event.target.value as string);
    };

    const sendSearch = () => {
        props.searchEquipment(type, typeItem, klm, props.rectangle);
    };

    const resetSearch = () => {
        setType('EQUIPMENT');
        setTypeItem('');
        setKlm(props.klm);
        props.setKlm(props.klm);
    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-error-label">Тип поиска</InputLabel>
                <Select
                    fullWidth
                    className={classes.select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    onChange={typeChange}
                >
                    <MenuItem value={'EQUIPMENT'}>По типу техники</MenuItem>
                    <MenuItem value={'ORDER'}>По типу работ</MenuItem>
                </Select>
            </FormControl>

            <FormControl className={classes.formControl} error={!typeItem}>
                <InputLabel id="demo-simple-select-error-label">Выберите {(type === 'EQUIPMENT') ? 'технику' : 'работу'}</InputLabel>
                <Select
                    fullWidth
                    className={classes.select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={typeItem}
                    onChange={typeItemsChange}
                >
                    <MenuItem value="" disabled><em>Не выбрано</em></MenuItem>
                    {(type === 'ORDER') && workTypes.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                    {(type === 'EQUIPMENT') && equipmentTypes.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                </Select>
                {!typeItem && <FormHelperText>{type === 'ORDER' ? 'Тип работ не выбран' : 'Тип техники не выбран'}</FormHelperText>}
            </FormControl>
            <h4 className={classes.h4}>Область, км</h4>
            <Slider className={classes.slider} value={klm} min={5} max={300} onChange={klmChange} aria-labelledby="continuous-slider" valueLabelDisplay="on" step={5} />
            <Grid container spacing={1} justify="space-between">
                <Grid item>
                    <Button size='small' variant="contained" color="secondary" onClick={resetSearch}>Сбросить</Button>
                </Grid>
                <Grid item>
                    <Button size='small' variant="contained" color="primary" onClick={sendSearch} disabled={typeItem === ""}>Поиск</Button>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        workTypes: state.layout.works,
        equipmentTypes: state.layout.equipments,
        rectangle: state.map.rectangle,
        klm: state.map.klm
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        searchEquipment: (type: SearchType, typeItem: string, klm: number, rectangle: LatLngTuple[]) => dispatch({ type: SEARCH_EQUIPMENT, typeSearch: type, typeItem, klm, rectangle }),
        setKlm: (klm: number) => dispatch({ type: SET_KLM, klm }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SearchEquipments);