import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Select, Typography, Popover } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { connect, ConnectedProps } from "react-redux";
import { IEquipment, IEquipmentForm, GET_EQUIPMENT, POST_EQUIPMENT, SET_EQUIPMENT } from '../../store/userData/types';
import { IType } from '../../store/layout/types';
import Map from './../containers/Map';
import { RootState } from "../../store";
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    formControl: {
        margin: '8px auto',
        minWidth: 280,
        maxWidth: 960,
        display: 'block'
    },
    select: {
        minWidth: 280,
        width: '100%'
    },
    image: {
        maxWidth: "300px",
        marginTop: '8px'
    },
    marginTop: {
        marginTop: '8px'
    }
}));

interface SettingsProps {
}

const EquipmetnInformation = (props: SettingsProps & PropsFromRedux) => {
    const classes = useStyles();
    const { currentEquipment, workTypes, equipmentTypes, center } = props;
    const [hidden, setHidden] = useState(currentEquipment?.hidden || false);
    const [publicPathLink, setPublicPathLink] = useState(currentEquipment?.publicPathLink || '');
    const [type, setType] = useState(currentEquipment?.type || '');
    const [coords, setCoords] = useState((currentEquipment?.coordLat && currentEquipment?.coordLng) ? { lat: currentEquipment?.coordLat, lng: currentEquipment?.coordLng } : { lat: center.lat, lng: center.lng });
    const [name, setName] = useState(currentEquipment?.name || '');
    const [phone, setPhone] = useState(currentEquipment?.phone || '');
    const [description, setDescription] = useState(currentEquipment?.description || '');
    const [image, setImage] = useState<FileList>();
    const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>();
    const [works, setWorks] = useState<string[]>([]);
    const [price, setPrice] = useState<number | null>(currentEquipment?.price || null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const history = useHistory();

    const onCoordsChange = (coords: { lat: number, lng: number }) => {
        setCoords(coords);
    };

    const typeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as string);
    };
    const nameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setName(event.target.value as string);
    };
    const phoneChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhone(event.target.value as string);
    };
    const descriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDescription(event.target.value as string);
    };
    const priceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPrice(event.target.value as number);
    };
    const priceExistChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(event.target.checked ? currentEquipment?.price || 0 : null);
    };
    const hiddenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHidden(!(event.target.checked as boolean));
    };
    const publicPathLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked as boolean ? (currentEquipment?.publicPathLink || 'new') : '';
        setPublicPathLink(value);
    };
    const checkboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (!event.target.checked) {
            setWorks(works.filter(item => item !== id));
        } else {
            setWorks([...works, id]);
        }
    };

    const saveEquipment = () => {
        props.postEquipment({
            _id: currentEquipment?._id || undefined,
            hidden,
            type,
            name,
            coordLat: coords?.lat,
            coordLng: coords?.lng,
            phone,
            description,
            price,
            publicPathLink,
            works: image ? JSON.stringify(works) : works,
            image: image ? image[0] : null
        });
        if (!currentEquipment?._id) {
            setName('');
            setDescription('');
        }
        history.push('/equipments');
    };

    useEffect(() => {
        setPublicPathLink(currentEquipment?.publicPathLink || '');
        setHidden(currentEquipment?.hidden || false);
        setType(currentEquipment?.type || '');
        setName(currentEquipment?.name || '');
        setPhone(currentEquipment?.phone || '');
        setDescription(currentEquipment?.description || '');
        setImageSrc(currentEquipment?.icoUrl ? '/' + currentEquipment.icoUrl : '');
        setWorks(currentEquipment?.works || []);
        setPrice(currentEquipment?.price || null);
        setCoords((currentEquipment?.coordLat && currentEquipment?.coordLng) ? { lat: currentEquipment.coordLat, lng: currentEquipment.coordLng } : { lat: center.lat, lng: center.lng });
    }, [currentEquipment]);

    const equipmentImageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;

        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = e => setImageSrc(reader.result);
            reader.readAsDataURL(files[0]);
        }
        setImage(files);
    };

    const helpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button onClick={saveEquipment} variant="outlined" color="primary">Сохранить</Button>
            <FormControlLabel
                id="hidden"
                className={classes.formControl}
                control={<Switch checked={!hidden} onChange={hiddenChange} />}
                label={!hidden ? "Опубликовано" : "Не опубликовано"}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FormControlLabel
                    id="publicPathLink"
                    className={classes.formControl}
                    control={<Switch checked={!!publicPathLink} onChange={publicPathLinkChange} />}
                    label={publicPathLink ? "Публичный маршрут" : "Непубличный маршрут"}
                />
                <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}                >
                    <Typography style={{ margin: '8px', maxWidth: '600px' }}>Данная настройка необходима для функции GPS-мониторинга техники(через приложение на телефоне). Eсли вы планируете показывать другим пользователям сервиса текущее местоположение техники/груза на карте, то установите этот флаг в положение "Публичный маршрут"</Typography>
                </Popover>
                <IconButton aria-label="delete" onClick={helpClick}>
                    <HelpIcon fontSize="small" />
                </IconButton>
            </div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="type-label">Тип</InputLabel>
                <Select
                    className={classes.select}
                    labelId="type-label"
                    id="type"
                    value={type}
                    onChange={typeChange}
                    label="Тип"

                >
                    <MenuItem value=""><em>Не выбрано</em></MenuItem>
                    {equipmentTypes?.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined"
                    label="Название"
                    fullWidth
                    defaultValue={name} margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    onChange={nameChange}
                    value={name}
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined"
                    label="Телефон"
                    fullWidth
                    defaultValue={phone} margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    onChange={phoneChange}
                    value={phone}
                />
            </FormControl>

            <div>
                <div>
                    <Button variant="contained" component="label">
                        Фотография техники
                        <input type="file" style={{ display: "none" }} onChange={equipmentImageChange} accept=".jpg,.png,.jpeg" />
                    </Button>
                </div>
                {imageSrc && <img src={imageSrc as string} alt="Картинка" className={classes.image} />}
            </div>

            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined"
                    label="Описание"
                    fullWidth
                    multiline
                    rows={4}
                    defaultValue={description} margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    onChange={descriptionChange}
                    value={description}
                />
            </FormControl>

            <FormGroup row className={classes.formControl}>
                {workTypes?.map(item =>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={works.includes(item._id)}
                                onChange={(e) => checkboxChange(e, item._id)}
                                name={item.value}
                            />}
                        label={item.name} />)}
            </FormGroup>

            <Map height={'250px'} initMarker={coords} onChange={onCoordsChange} />

            <FormGroup row style={{ justifyContent: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={price !== null}
                            onChange={priceExistChange}
                            color="secondary"
                        />
                    }
                    label="Продаётся"
                />
                <FormControl variant="outlined">
                    <TextField
                        disabled={price === null}
                        variant="outlined"
                        label="Цена"
                        type="number"
                        defaultValue={0} margin="normal"
                        InputLabelProps={{ shrink: true, }}
                        onChange={priceChange}
                        value={price}
                    />
                </FormControl>
            </FormGroup>

            <Button className={classes.marginTop} onClick={saveEquipment} variant="outlined" color="primary">Сохранить</Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        currentEquipment: state.userData.currentEquipment,
        workTypes: state.layout.works,
        equipmentTypes: state.layout.equipments,
        center: state.map.center
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getEquipment: (id: string) => dispatch({ type: GET_EQUIPMENT, id, getType: 'equipment', setType: SET_EQUIPMENT }),
        postEquipment: (equipment: IEquipmentForm) => dispatch({ type: POST_EQUIPMENT, equipment }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EquipmetnInformation);