import React from 'react';

import {
    makeStyles
} from "@material-ui/core";

const instructions = {
    intro: [
        `На данный момент Сервис Mazuta.pro работает в БЕСПЛАТНОМ режиме`,
        `1. Сервис предоставляет возможность разместить собственную спецтехнику и заказы на доске объявлений`,
        `2. Сервис предоставляет возможность GPS-мониторинга спецтехники(публично или приватно)`,
        `3. Сервис предоставляет возможность Фото-мониторинга спецтехники(публично или приватно)`,
    ],
    equipSearch: [
        `Для поиска спецтехники или заказов, необходимо зайти на главный экран сервиса("Карта") и нажать на иконку "Поиск"(увеличительное стекло). В появившемся окне необходимо ввести нужные параметры и нажать кнопку Поиск.`,
        `Для размещения собственной спецтехники или заказов на сервисе, необходимо:`,
        `1. Создать аккаунт на сервисе Mazuta.pro`,
        `2. Создать запись во вкладке "Техника"`,
        `3. Создать запись во вкладке "Заказы"`,
    ],
    workSearch: [
        "",
    ],
    gpsMonitioring: [
        `1. Создать аккаунт на сервисе Mazuta.pro`,
        `2. Создать запись во вкладке "Техника"(заполните форму любыми данными, если запись создается не для спецтехники, а для мониторинга других объектов)`,
        `3. Скачать и установить Android-приложение(далее Приложение) на мобильное устройство (в разработке)`,
        `4. При запуске приложения на телефоне, дать приложению запрашиваемые разрешения к ресурсам телефона(GPS, камера, "работа в фоновом режиме")`,
        `5. В Приложении:`,
        `5.1. Залогиниться под собственным аккаунтом`,
        `5.2. Нажать кнопку "Вкл" в разделе Координаты`,
        `5.3. Выбрать технику для которой необходима запись координат`,
        `5.4. После окончания требуемого временного периода GPS-мониторинга, нажать кнопку "Выкл" в разделе Координаты`,
        `6. Для просмотра маршрута движения техники в сервисе Mazuta.pro, необходимо на главном экране("Карта") нажать на синюю иконку Техника. 
        В открывшемся окне можно будет выбрать режим отображения записанных маршрутов движения`,
        `7. Для того чтобы иметь возможность записывать GPS-данные для техники из другого аккаунта, необходимо быть добавленным в Партенеры этого аккаунта. Это можно сделать во вкладке Настройки`
    ],
    photoMonitoring: [
        `1. Создать аккаунт на сервисе Mazuta.pro`,
        `2. Создать запись во вкладке "Техника"(заполните форму любыми данными, если запись создается не для спецтехники, а для мониторинга других объектов)`,
        `3. Скачать и установить Android-приложение(далее Приложение) на мобильное устройство (в разработке)`,
        `4. При запуске приложения на телефоне, дать приложению запрашиваемые разрешения к ресурсам телефона(GPS, камера, "работа в фоновом режиме")`,
        `5. В Приложении:`,
        `5.1. Залогиниться под собственным аккаунтом`,
        `5.2. Нажать кнопку "Вкл" в разделе Фото`,
        `5.3. Выбрать технику для которой необходима запись координат`,
        `5.4. После окончания требуемого временного периода Фото-мониторинга, нажать кнопку "Выкл" в разделе Фото`,
        `6. Для просмотра фотографий работы техники в сервисе Mazuta.pro, необходимо на главном экране("Карта") нажать на синюю иконку Техника. 
        В открывшемся окне можно будет выбрать карточку техники. 
        После выбора карточки откроется окно где отобразятся все записанные Приложением фотографии с привязкой ко времени. 
        Также при одновременной записи координат, есть возможность отобразить сделанные фотографии на Карте.`,
        `7. Для того чтобы иметь возможность записывать Фото-данные для техники из другого аккаунта, необходимо быть добавленным в Партенеры этого аккаунта. Это можно сделать во вкладке Настройки`
    ],
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '960px',
        margin: 'auto',
        padding: '8px',
        textAlign: 'left'
    },
    video: {
        maxWidth: "280px",
        width: "100%",
        margin: '0 auto 8px',
        height: "200px"
    },
}));

const Instruction = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h1>Инструкция сервиса</h1>
            {instructions.intro.map(v => <p>{v}</p>)}
            <div>
                <iframe className={classes.video} src="https://www.youtube.com/embed/clKRKi1k15k" title="YouTube video player" frameBorder='0' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <h2>Аренда спецтехники и поиск заказов</h2>
            {instructions.equipSearch.map(v => <p>{v}</p>)}
            <h2>Запись местоположения</h2>
            {instructions.gpsMonitioring.map(v => <p>{v}</p>)}
            <h2>Фото-контроль работ</h2>
            {instructions.photoMonitoring.map(v => <p>{v}</p>)}
        </div>
    );
};

export default Instruction;