import { call, put, takeLatest } from 'redux-saga/effects'
import {
    GET_TYPES,
    SET_TYPES,
    GetTypes
} from './types'

function* fetchTypesSaga(action: GetTypes) {
    try {
        const result = yield call(fetchTypes, action.urlType);
        return yield put({ type: SET_TYPES, works: result.worksTypes, equipments: result.equipmentsTypes });
    } catch (e) {

    }
}

function* layoutSaga() {
    yield takeLatest(GET_TYPES, fetchTypesSaga);
}

function fetchTypes(urlType: string) {
    return fetch("/api/get-types?url=" + urlType, {
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

export default layoutSaga;