import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import { RootState } from "../../store";
import { GET_PUBLIC_PATH } from '../../store/userData/types';
import { SET_CENTER, SET_SHOW_RECTANGLE, MapStates, SET_MAP_STATE } from '../../store/map/types';
import { LatLng } from 'leaflet';
import { getRectangle } from '../../store/map/reducers';

import {
    makeStyles,
    CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0
    }
}));

const PublicView = (props: PropsFromRedux) => {
    const classes = useStyles();
    const { id, token } = useParams<{ id: string, token: string }>();
    const { publicData, setShowRectangle } = props;
    const history = useHistory();

    useEffect(() => {
        setShowRectangle(false);
        props.getPublicData(id, token);
    }, []);

    useEffect(() => {
        if (publicData && publicData.equipment) {
            const length = publicData.locations.length;
            if (length) { props.setCenter(publicData.locations[length - 1].coords[0], publicData.locations[length - 1].coords[1]); }
            props.setMapState('TRACK');
            history.push('/map');
        }
    }, [publicData]);

    return (
        <>
            <>
                <h3>Получение разрешения на просмотр публичного текущего маршрута и фото техники...</h3>
                <CircularProgress color="secondary" />
            </>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        publicData: state.userData.publicData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCenter: (lat: number, lng: number) => dispatch({ type: SET_CENTER, center: new LatLng(lat, lng), rectangle: getRectangle(new LatLng(lat, lng), 1) }),
        getPublicData: (id: string, token: string) => dispatch({ type: GET_PUBLIC_PATH, id, token }),
        setShowRectangle: (show: boolean) => dispatch({ type: SET_SHOW_RECTANGLE, show }),
        setMapState: (state: MapStates) => dispatch({ type: SET_MAP_STATE, state }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PublicView);