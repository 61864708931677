import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { connect, ConnectedProps } from "react-redux";
import { IOrder, IOrderForm, GET_ORDER, POST_ORDER, SET_ORDER } from '../../store/userData/types';
import { IType } from '../../store/layout/types';
import Map from './../containers/Map';
import { RootState } from "../../store";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    formControl: {
        margin: '8px auto',
        minWidth: 280,
        maxWidth: 960,
        display: 'block'
    },
    select: {
        minWidth: 280,
        width: '100%'
    },
    image: {
        maxWidth: "300px",
        marginTop: '8px'
    },
    marginTop: {
        marginTop: '8px'
    }
}));

interface SettingsProps {
}

const OrderInformation = (props: SettingsProps & PropsFromRedux) => {
    const classes = useStyles();
    const { currentOrder, equipTypes, orderTypes, center } = props;
    const [hidden, setHidden] = useState(currentOrder?.hidden || false);
    const [type, setType] = useState(currentOrder?.type || '');
    const [coords, setCoords] = useState((currentOrder?.coordLat && currentOrder?.coordLng) ? { lat: currentOrder?.coordLat, lng: currentOrder?.coordLng } : { lat: center.lat, lng: center.lng });
    const [name, setName] = useState(currentOrder?.name || '');
    const [phone, setPhone] = useState(currentOrder?.phone || '');
    const [description, setDescription] = useState(currentOrder?.description || '');
    const [image, setImage] = useState<FileList>();
    const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>();
    const [equips, setEquips] = useState<string[]>([]);
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const onCoordsChange = (coords: { lat: number, lng: number }) => {
        setCoords(coords);
    };

    const typeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as string);
    };
    const nameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setName(event.target.value as string);
    };
    const phoneChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPhone(event.target.value as string);
    };
    const descriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDescription(event.target.value as string);
    };
    const hiddenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHidden(!(event.target.checked as boolean));
    };
    const checkboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (!event.target.checked) {
            setEquips(equips.filter(item => item !== id));
        } else {
            setEquips([...equips, id]);
        }
    };

    const saveOrder = () => {
        props.postOrder({
            _id: currentOrder?._id || undefined,
            hidden,
            type,
            name,
            coordLat: coords?.lat,
            coordLng: coords?.lng,
            phone,
            description,
            equips: image ? JSON.stringify(equips) : equips,
            image: image ? image[0] : null
        });
        if (!currentOrder?._id) {
            setName('');
            setDescription('');
        }
        history.push('/orders');
    };

    useEffect(() => {
        setHidden(currentOrder?.hidden || false);
        setType(currentOrder?.type || '');
        setName(currentOrder?.name || '');
        setPhone(currentOrder?.phone || '');
        setDescription(currentOrder?.description || '');
        setImageSrc(currentOrder?.icoUrl ? '/' + currentOrder.icoUrl : '');
        setEquips(currentOrder?.equips || []);
        setCoords((currentOrder?.coordLat && currentOrder?.coordLng) ? { lat: currentOrder.coordLat, lng: currentOrder.coordLng } : { lat: center.lat, lng: center.lng });
    }, [currentOrder]);

    useEffect(() => { props.getOrder(id) }, [id]);

    const orderImageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;

        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = e => setImageSrc(reader.result);
            reader.readAsDataURL(files[0]);
        }
        setImage(files);
    };

    return (
        <>
            <Button onClick={saveOrder} variant="outlined" color="primary">Сохранить</Button>
            <FormControlLabel
                className={classes.formControl}
                control={<Switch checked={!hidden} onChange={hiddenChange} />}
                label={!hidden ? "Опубликовано" : "Не опубликовано"}
            />
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="type-label">Тип</InputLabel>
                <Select
                    className={classes.select}
                    labelId="type-label"
                    id="type"
                    value={type}
                    onChange={typeChange}
                    label="Тип"
                >
                    <MenuItem value=""><em>Не выбрано</em></MenuItem>
                    {orderTypes?.map(item => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined"
                    label="Название"
                    fullWidth
                    defaultValue={name} margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    onChange={nameChange}
                    value={name}
                />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined"
                    label="Телефон"
                    fullWidth
                    defaultValue={phone} margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    onChange={phoneChange}
                    value={phone}
                />
            </FormControl>

            <div>
                <div>
                    <Button variant="contained" component="label">
                        Фотография для заказа
                        <input type="file" style={{ display: "none" }} onChange={orderImageChange} accept=".jpg,.png,.jpeg" />
                    </Button>
                </div>
                {imageSrc && <img src={imageSrc as string} alt="Картинка" className={classes.image} />}
            </div>

            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    variant="outlined"
                    label="Описание"
                    fullWidth
                    multiline
                    rows={4}
                    defaultValue={description} margin="normal"
                    InputLabelProps={{ shrink: true, }}
                    onChange={descriptionChange}
                    value={description}
                />
            </FormControl>

            <FormGroup row className={classes.formControl}>
                {equipTypes?.map(item =>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={equips.includes(item._id)}
                                onChange={(e) => checkboxChange(e, item._id)}
                                name={item.value}
                            />}
                        label={item.name} />)}
            </FormGroup>

            <Map height={'250px'} initMarker={coords} onChange={onCoordsChange} />

            <Button className={classes.marginTop} onClick={saveOrder} variant="outlined" color="primary">Сохранить</Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        currentOrder: state.userData.currentOrder,
        equipTypes: state.layout.equipments,
        orderTypes: state.layout.works,
        center: state.map.center
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getOrder: (id: string) => dispatch({ type: GET_ORDER, id, getType: 'order', setType: SET_ORDER }),
        postOrder: (order: IOrderForm) => dispatch({ type: POST_ORDER, order }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(OrderInformation);