import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    SET_EQUIPMENTS, SET_ORDERS, SET_INCIDENTS, SET_PRIORITY_ORDERS, SET_PRIORITY_EQUIPMENTS,
    GET_EQUIPMENTS, POST_EQUIPMENT, UPDATE_EQUIPMENT, DELETE_EQUIPMENT,
    GET_INCIDENTS, GET_EQUIPMENT, SET_EQUIPMENT,
    GET_PRIORITY_ORDERS,
    GET_PRIORITY_EQUIPMENTS,
    GET_ORDERS,
    GET_ORDER,
    POST_ORDER,
    SET_ORDER,
    GET_PUBLIC_PATH,
    SET_PUBLIC_PATH,
    ADD_TO_FAVORITE, GET_DAILY_DATA, GetDailyData, SET_DAILY_DATA, GET_AVTOPARK, SET_AVTOPARK,
    GetData, IEquipmentForm, GetDataId, PostEquipment, PostOrder, IOrderForm, AddFavorite, GetPublicPath, GetAvtopark
} from './types'

function* fetchUserEquipmentsSaga(action: GetData) {
    try {
        const result = yield call(fetchData, 'get-equipments');
        return yield put({ type: SET_EQUIPMENTS, equipments: result.equipments });
    } catch (e) { }
}

function* fetchUserOrdersSaga(action: GetData) {
    try {
        const result = yield call(fetchData, 'get-orders');
        return yield put({ type: SET_ORDERS, orders: result.orders });
    } catch (e) { }
}

function* fetchUserIncidentsSaga(action: GetData) {
    try {
        const result = yield call(fetchData, 'get-incidents');
        return yield put({ type: SET_INCIDENTS, incidents: result.incidents });
    } catch (e) { }
}

function* fetchUserPriorityEquipmentsSaga(action: GetData) {
    try {
        const result = yield call(fetchData, 'get-favorite-equipments');
        return yield put({ type: SET_PRIORITY_EQUIPMENTS, priorityEquipments: result.equips });
    } catch (e) { }
}

function* fetchUserDataSaga(action: GetDataId) {
    try {
        if (action.id === 'new') {
            return yield put({ type: action.setType, equipment: null, order: null });
        }
        const result = yield call(fetchDataId, action.getType, action.id);
        return yield put({ type: action.setType, [action.getType]: result[action.getType] });
    } catch (e) { }
}

function* fetchDailyDataSaga(action: GetDailyData) {
    try {
        const result = yield call(fetchDailyData);
        return yield put({ type: SET_DAILY_DATA, allDailyData: result.allDailyData });
    } catch (e) { }
}

function* fetchUserPriorityOrdersSaga(action: GetData) {
    try {
        const result = yield call(fetchData, 'get-favorite-works');
        return yield put({ type: SET_PRIORITY_ORDERS, priorityOrders: result.works });
    } catch (e) { }
}

function* postAddFavorite(action: AddFavorite) {
    try {
        const result = yield call(postFavorite, action.typeFav, action.id);
        return yield put({ type: GET_EQUIPMENTS });
    } catch (e) { }
}

function* postEquipmentSaga(action: PostEquipment) {
    try {
        const result = yield call(postData, 'equipment', action.equipment, 'POST');
        return yield put({ type: GET_EQUIPMENTS });
    } catch (e) { }
}

function* postOrderSaga(action: PostOrder) {
    try {
        const result = yield call(postData, 'work', action.order, 'POST');
        return yield put({ type: GET_ORDERS });
    } catch (e) { }
}

function* getPublicPathSaga(action: GetPublicPath) {
    try {
        yield put({ type: SET_PUBLIC_PATH, data: null });
        const result = yield call(getPublicData, action.id, action.token);
        return yield put({ type: SET_PUBLIC_PATH, data: result.data });
    } catch (e) { }
}

function* getAvtoparkSaga(action: GetAvtopark) {
    try {
        const result = yield call(getAvtoparkData, action.name);
        return yield put({ type: SET_AVTOPARK, data: result });
    } catch (e) { }
}

function* userDataSaga() {
    yield takeLatest(GET_AVTOPARK, getAvtoparkSaga);
    yield takeLatest(GET_PUBLIC_PATH, getPublicPathSaga);
    yield takeLatest(UPDATE_EQUIPMENT, postEquipmentSaga);
    yield takeLatest(DELETE_EQUIPMENT, postEquipmentSaga);
    yield takeLatest(POST_EQUIPMENT, postEquipmentSaga);
    yield takeLatest(POST_ORDER, postOrderSaga);
    yield takeLatest(GET_EQUIPMENT, fetchUserDataSaga);
    yield takeLatest(GET_ORDER, fetchUserDataSaga);
    yield takeLatest(GET_EQUIPMENTS, fetchUserEquipmentsSaga);
    yield takeLatest(GET_ORDERS, fetchUserOrdersSaga);
    yield takeLatest(GET_INCIDENTS, fetchUserIncidentsSaga);
    yield takeLatest(GET_PRIORITY_ORDERS, fetchUserPriorityOrdersSaga);
    yield takeLatest(GET_PRIORITY_EQUIPMENTS, fetchUserPriorityEquipmentsSaga);
    yield takeLatest(GET_DAILY_DATA, fetchDailyDataSaga);
    yield takeLatest(ADD_TO_FAVORITE, postAddFavorite);
}

function getAvtoparkData(name: string) {
    return fetch(`/api/get-avtopark?userUrl=${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function getPublicData(id: string, token: string) {
    return fetch(`/api/get-public-data/${id}/${token}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function fetchData(url: string) {
    return fetch("/api/" + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function fetchDataId(url: string, id: string) {
    return fetch("/api/" + url + '/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function fetchDailyData() {
    return fetch("/api/get-daily-data", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function postData(url: string, data: IEquipmentForm | IOrderForm, type: "POST" | "DELETE") {
    let formData = new FormData();
    const typeUrl = type === 'POST' ? (data._id ? 'update-' : 'create-') : 'delete-';
    for (const name in data) {
        formData.append(name, (data as any)[name] ? (data as any)[name] : '');
    }
    return fetch("/api/" + typeUrl + url, {
        method: type === 'DELETE' ? type : (data._id ? 'PUT' : 'POST'),
        headers: data.image ? {} : { 'Content-Type': 'application/json;charset=utf-8' },
        body: data.image ? formData : JSON.stringify(data)
    })
        .then(response => response.json())
}

function postFavorite(type: number, id: string) {
    return fetch("/api/add-favorite", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        body: JSON.stringify({ type, id })
    })
        .then(response => response.json())
}

export default userDataSaga;