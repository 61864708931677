import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EquipmetnInformation from './EquipmetnInformation';
import EquipmetnLocations from './EquipmentLocations';
import { GET_EQUIPMENT, SET_EQUIPMENT } from '../../store/userData/types';
import {connect, ConnectedProps} from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
}));
interface EquipmentProps {
}

const Equipment = (props: EquipmentProps & PropsFromRedux) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { id } = useParams<{id:string}>();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    props.getEquipment(id)},[id]
  );

  return (
    <div className={classes.root}>
  <AppBar position="static" color="default">
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      <Tab label="Информация" {...a11yProps(0)} />
      <Tab label="Контроль" {...a11yProps(1)} />
      <Tab label="Дневник" {...a11yProps(2)} />
    </Tabs>
  </AppBar>

    <TabPanel value={value} index={0} dir={theme.direction}>
      <EquipmetnInformation />
    </TabPanel>
    <TabPanel value={value} index={1} dir={theme.direction}>
      <EquipmetnLocations equipmentId={id}/>
    </TabPanel>
    <TabPanel value={value} index={2} dir={theme.direction}>
      ---
    </TabPanel>
    </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
      getEquipment: (id: string) => dispatch({ type: GET_EQUIPMENT, id, getType: 'equipment', setType: SET_EQUIPMENT}),
      resetEquipment: () => dispatch({ type: SET_EQUIPMENT, }),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Equipment);


