import {
    UserDataState,
    SET_EQUIPMENTS,
    UserDataActionTypes,
    SET_ORDERS,
    SET_PRIORITY_ORDERS,
    SET_PRIORITY_EQUIPMENTS,
    SET_INCIDENTS,
    SET_EQUIPMENT,
    SET_ORDER,
    SET_SHOW_PHOTO,
    SET_DAILY_DATA,
    SET_SHOW_PATH,
    SET_PATH_INTERVAL,
    SET_TIME_PATH,
    SET_PUBLIC_PATH,
    SET_AVTOPARK,
    IAllDailyData,
    SET_TEST_PATHS,
    SET_TEST_MONITORING
} from './types'

const initialState: UserDataState = {
    currentEquipment: null,
    currentOrder: null,
    currentPriorityEquipment: null,
    currentPriorityOrder: null,
    currentIncident: null,
    equipments: [],
    priorityEquipments: [],
    orders: [],
    priorityOrders: [],
    incidents: [],
    allDailyData: [],
    publicData: null,
    avtopark: null,
    minTime: null,
    maxTime: null,
    testPaths: [],
    testMonitoring: null
};

export const getMin = (data: IAllDailyData[], publicPath: IAllDailyData | null) => {
    let min = Number.MAX_VALUE;
    data && data.map(v => {
        if (v.photos && v.photos.length && v.photos[0].timestamp < min) { min = v.photos[0].timestamp; }
        if (v.locations && v.locations.length && v.locations[0].timestamp < min) { min = v.locations[0].timestamp; }
    });
    if (publicPath) {
        if (publicPath.photos.length && publicPath.photos[0].timestamp < min) { min = publicPath.photos[0].timestamp; }
        if (publicPath.locations.length && publicPath.locations[0].timestamp < min) { min = publicPath.locations[0].timestamp; }
    }
    return (min === Number.MAX_VALUE) ? null : min;
}

export const getMax = (data: IAllDailyData[], publicPath: IAllDailyData | null) => {
    let max = 0;
    data && data.map(v => {
        if (v.photos && v.photos.length && v.photos[v.photos.length - 1].timestamp > max) { max = v.photos[v.photos.length - 1].timestamp; }
        if (v.locations && v.locations.length && v.locations[v.locations.length - 1].timestamp > max) { max = v.locations[v.locations.length - 1].timestamp; }

    });
    if (publicPath) {
        if (publicPath.photos.length && publicPath.photos[publicPath.photos.length - 1].timestamp > max) { max = publicPath.photos[publicPath.photos.length - 1].timestamp; }
        if (publicPath.locations.length && publicPath.locations[publicPath.locations.length - 1].timestamp > max) { max = publicPath.locations[publicPath.locations.length - 1].timestamp; }
    }
    return (max === 0) ? null : max;
}

export function userDataReducer(
    state = initialState,
    action: UserDataActionTypes
): UserDataState {
    const publicData = state.publicData;
    switch (action.type) {
        case SET_EQUIPMENTS:
            return {
                ...state,
                equipments: [...action.equipments]
            }
        case SET_EQUIPMENT:
            return {
                ...state,
                currentEquipment: action.equipment
            }
        case SET_ORDER:
            return {
                ...state,
                currentOrder: action.order
            }
        case SET_ORDERS:
            return {
                ...state,
                orders: [...action.orders]
            }
        case SET_PRIORITY_ORDERS:
            return {
                ...state,
                priorityOrders: [...action.priorityOrders]
            }
        case SET_PRIORITY_EQUIPMENTS:
            return {
                ...state,
                priorityEquipments: [...action.priorityEquipments]
            }
        case SET_INCIDENTS:
            return {
                ...state,
                incidents: [...action.incidents]
            }
        case SET_DAILY_DATA:
            const all = action.allDailyData.map(v => {
                return { ...v, showPath: true, showPhoto: false, showTime: true, pathInterval: 3200 }
            });
            return {
                ...state,
                minTime: getMin(all, state.publicData),
                maxTime: getMax(all, state.publicData),
                allDailyData: [...all]
            }
        case SET_SHOW_PHOTO:
            if (publicData && action.equipmentId === 'public') {
                publicData.showPhoto = action.showPhoto;
            }
            const allDailyData = state.allDailyData.map(item => {
                if (item.equipmentId === action.equipmentId) {
                    item.showPhoto = action.showPhoto;
                }
                return item;
            });
            return {
                ...state,
                allDailyData: [...allDailyData],
                publicData: publicData ? { ...publicData } : publicData
            }
        case SET_SHOW_PATH:
            if (publicData && action.equipmentId === 'public') {
                publicData.showPath = action.showPath;
            }
            const show = state.allDailyData.map(item => {
                if (item.equipmentId === action.equipmentId) {
                    item.showPath = action.showPath;
                }
                return item;
            });
            return {
                ...state,
                allDailyData: [...show],
                minTime: getMin(show, state.publicData),
                maxTime: getMax(show, state.publicData),
                publicData: publicData ? { ...publicData } : publicData
            }
        case SET_TIME_PATH:
            if (publicData && action.equipmentId === 'public') {
                publicData.showTime = action.showTime;
            }
            const time = state.allDailyData.map(item => {
                if (item.equipmentId === action.equipmentId) {
                    item.showTime = action.showTime;
                }
                return item;
            });
            return {
                ...state,
                allDailyData: [...time],
                publicData: publicData ? { ...publicData } : publicData
            }
        case SET_PATH_INTERVAL:
            if (publicData && action.equipmentId === 'public') {
                publicData.pathInterval = action.pathInterval;
            }
            const interval = state.allDailyData.map(item => {
                if (item.equipmentId === action.equipmentId) {
                    item.pathInterval = action.pathInterval;
                }
                return item;
            });
            return {
                ...state,
                allDailyData: [...interval],
                publicData: publicData ? { ...publicData } : publicData
            }
        case SET_PUBLIC_PATH:
            return {
                ...state,
                minTime: getMin(state.allDailyData, action.data),
                maxTime: getMax(state.allDailyData, action.data),
                publicData: action.data ? { ...action.data, showPath: true, showPhoto: false, showTime: true, pathInterval: 3200, equipmentId: 'public' } : null
            }
        case SET_AVTOPARK:
            return {
                ...state,
                avtopark: { ...action.data }
            }
        case SET_TEST_PATHS:
            return {
                ...state,
                testPaths: [...action.testPaths],
                minTime: getMin(action.testPaths, null),
                maxTime: getMax(action.testPaths, null)
            }
        case SET_TEST_MONITORING:
            return {
                ...state,
                testMonitoring: action.testMonitoring ? { ...action.testMonitoring } : null
            }
        default:
            return state
    }
}