import { LatLng, LatLngTuple } from 'leaflet';
import {
    MapState,
    MapActionTypes,
    SET_MAP_DATA,
    SET_KLM,
    SET_SEARCH_ORDER,
    SET_SEARCH_EQUIPMENT,
    SET_CENTER,
    SET_SHOW_RECTANGLE,
    SET_MAP_STATE, SEARCH_EQUIPMENT, SEARCH_ORDER
} from './types'

const KLM_IN_DEGREE = 111.3;
const latToKlm = (lng: number) => {
    return 111.3 * Math.cos(lng * Math.PI / 180);
}

export const getRectangle = (coords: { lat: number, lng: number }, sizeKlm: number): LatLngTuple[] => {
    return [
        [coords.lat - sizeKlm / KLM_IN_DEGREE, coords.lng - sizeKlm / latToKlm(coords.lat - (sizeKlm / KLM_IN_DEGREE))],
        [coords.lat + sizeKlm / KLM_IN_DEGREE, coords.lng + sizeKlm / latToKlm(coords.lat - (sizeKlm / KLM_IN_DEGREE))]
    ];
}

const initialState: MapState = {
    loadingSearch: false,
    orders: [],
    equipments: [],
    klm: 30,
    center: new LatLng(55.75, 37.610),
    rectangle: getRectangle(new LatLng(55.75, 37.610), 10),
    showRectangle: true,
    selectedItem: null,
    mapState: 'SEARCH'
};

export function mapReducer(
    state = initialState,
    action: MapActionTypes
): MapState {
    switch (action.type) {
        case SET_MAP_DATA:
            return {
                ...state,
                orders: [...action.orders],
                equipments: [...action.equipments],
                loadingSearch: false
            }
        case SET_KLM:
            return {
                ...state,
                klm: action.klm,
                rectangle: getRectangle(state.center, action.klm)
            }
        case SEARCH_EQUIPMENT:
            return {
                ...state,
                loadingSearch: true
            }
        case SEARCH_ORDER:
            return {
                ...state,
                loadingSearch: true
            }
        case SET_SEARCH_ORDER:
            return {
                ...state,
                orders: action.orders
            }
        case SET_SEARCH_EQUIPMENT:
            return {
                ...state,
                equipments: action.equipments
            }
        case SET_CENTER:
            return {
                ...state,
                center: action.center,
                rectangle: action.rectangle ? action.rectangle : state.rectangle
            }
        case SET_SHOW_RECTANGLE:
            return {
                ...state,
                showRectangle: action.show
            }
        case SET_MAP_STATE:
            return {
                ...state,
                mapState: action.state,
                selectedItem: action.selectedItem || null
            }
        default:
            return state
    }
}