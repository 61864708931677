import React, { useEffect, useState, ReactDOM, useMemo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Slider from '@material-ui/core/Slider';
import CommuteIcon from '@material-ui/icons/Commute';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Rectangle } from 'react-leaflet';
import { Icon, LatLng, LatLngTuple } from 'leaflet';
import { divIcon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { CHANGE_CENTER, MapStates, SET_MAP_STATE } from '../../store/map/types';
import { connect, ConnectedProps } from "react-redux";
import MapPopup from './MapPopup';
import MapPhotos from './MapPhotos';
import { RootState } from "../../store";
import './map.css'
import MapPathLines from './MapPathLines';
import DialogSearch from "../search/DialogSearch";
import DialogDailyData from "../dailyData/DialogDailyData";
import Badge from '@material-ui/core/Badge';
import { IEquipment, IOrder } from '../../store/userData/types';
import Alert, { Text } from './Alert';
import MapTestMonitoring from './MapTestMonitoring';

const useStyles = makeStyles((theme: any) => ({
  articleImg: {
  },
  image: {
    width: '100%'
  },
  popup: {
  },
  iconEquip: {
    width: 'fit-content'
  },
  dailyButton: {
    position: 'absolute',
    right: '4px',
    top: '78px',
    zIndex: 401
  },
  searchButton: {
    position: 'absolute',
    right: '4px',
    top: '8px',
    zIndex: 401
  },
  slider: {
    width: 'calc(-16px + 100%)',
    position: 'absolute',
    bottom: '8px',
    padding: '2px 8px 8px 8px',
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  currentSlider: {
    margin: 'auto',
    color: '#fff'
  }
}));

interface MapProps {
  rectangleSize?: number;
  layoutPage?: boolean;
  height: string;
  initMarker?: { lat: number, lng: number } | null;
  onChange?: (coords: { lat: number, lng: number }) => void;
}

const Map: React.FC<MapProps & PropsFromRedux> = props => {
  const classes = useStyles();
  const blackOptions = { color: 'green' }
  const marker = new LatLng(props.initMarker?.lat || props.center.lat || 55.75, props.initMarker?.lng || props.center.lng || 37.61);
  const icon: Icon = new Icon({ iconUrl: '/img/equip.png' });
  const iconWork: Icon = new Icon({ iconUrl: '/img/work.png' });
  const [openSearch, setOpenSearch] = useState(false);
  const [map, setMap] = useState<any>(null);
  const [openGps, setOpenGps] = useState(false);
  const [alertText, setAlertText] = useState<Text>('');
  const [sliderValue, setSliderValue] = useState(0);
  const { mapState, selectedItem, minTime, maxTime, auth } = props;

  const onSliderChange = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  const getIcon = (type: string) => {
    const icon = document.createElement(`div`);
    icon.className = 'equip-icon';
    icon.style.cssText = 'width: fit-content;background-color: #f50057;padding: 4px;border-radius: 4px;position: absolute;left: -15px;color: white;font-size: 0.875rem;font-family: "Roboto", "Helvetica", "Arial", sans-serif;font-weight: 500;text-transform: uppercase;';
    icon.textContent = props.equipmentTypes?.find(v => v._id === type)?.name || 'type';
    return divIcon({
      html: icon
    });
  };

  function LocationMarker(p: { center: any }) {
    const map = useMapEvents({
      click(e) {
        if (props.onChange) {
          props.onChange(e.latlng);
          map.flyTo(e.latlng, 15);
        }
        props.layoutPage && (mapState === 'SEARCH') && props.setCenter(e.latlng, props.klm);
        if (mapState === 'SHOW_ONE') {
          props.setMapState('SEARCH');
        }
        setAlertText("GO_TO_FILTER");
      }
    })
    // p.center && map.flyTo(p.center as LatLng, 12);
    return <></>
  }

  useEffect(() => {
    map && (mapState !== 'TRACK') && map.flyTo(new LatLng(props.center.lat, props.center.lng), 10);
  }, [props.center]);

  useEffect(() => {
    setAlertText("CLICK_ON_MAP");
    //props.setCenter(new LatLng(props.center.lat || props.initMarker?.lat || 55.75, props.center.lng || props.initMarker?.lng || 37.61), props.klm);
  }, []);

  const mapCreated = (map: any) => { setMap(map); map.flyTo(new LatLng(props.center.lat, props.center.lng), 10); };

  const showMarker = (item: IEquipment | IOrder) => (
    item && item.coordLat && item.coordLng && <Marker
      key={item._id}
      position={[item.coordLat!, item.coordLng!]}
      title={item.name}
      icon={getIcon(item.type)}
    >
      <Popup className={classes.popup}>
        <MapPopup item={item} type={0} />
      </Popup>
    </Marker>);

  return (
    <div style={{ position: 'relative' }}>
      {props.layoutPage && <Fab color={(mapState !== 'TRACK') ? 'secondary' : 'primary'} aria-label="Поиск" onClick={() => setOpenSearch(true)} className={classes.searchButton}>
        <SearchIcon />
      </Fab>}
      {auth && props.layoutPage && <Fab color={(mapState === 'TRACK') ? 'secondary' : 'primary'} aria-label="Записи" onClick={() => setOpenGps(true)} className={classes.dailyButton}>
        {(mapState === 'TRACK') ? <Badge color='secondary' badgeContent={props.publicData ? (props.allDailyData.length + 1) : props.allDailyData.length} showZero>
          <CommuteIcon />
        </Badge> : <CommuteIcon />}
      </Fab>}
      {props.layoutPage && mapState === 'SEARCH' && <Alert text={alertText} buttonClick={() => { setOpenSearch(true) }} />}
      {
        ((props.layoutPage && mapState === 'TRACK') || (props.testPaths && (props.testPaths.length !== 0))) && minTime && maxTime && <div className={classes.slider}>
          {(!!sliderValue) && <div className={classes.currentSlider}>{new Date(sliderValue).toLocaleString()}</div>}
          <Slider aria-labelledby="discrete-slider-always" min={minTime} max={maxTime} onChange={onSliderChange} color="secondary" />
        </div>
      }
      <MapContainer
        whenCreated={mapCreated}
        center={marker}
        zoom={10}
        style={{ height: props.height, overflow: 'hidden' }}
      >
        <LocationMarker center={marker as LatLng} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {!props.layoutPage && marker &&
          <Marker position={[marker.lat, marker.lng]} icon={icon}></Marker>
        }
        {(mapState === 'SEARCH') && props.layoutPage && props.equipments && props.equipments.map(item => showMarker(item))}
        {(mapState === 'SHOW_ONE') && props.layoutPage && selectedItem && showMarker(selectedItem)}
        {props.layoutPage && props.orders && props.orders.map(item =>
          <Marker key={item._id} position={[item.coordLat!, item.coordLng!]} title={item.name} icon={iconWork}>
            <Popup className={classes.popup}>
              <MapPopup item={item} type={1} />
            </Popup>
          </Marker>
        )
        }

        {props.layoutPage && (mapState === 'SEARCH') && <Rectangle bounds={props.rectangle} pathOptions={blackOptions} />}
        {props.layoutPage && <MapPhotos />}
        {props.layoutPage && <MapPathLines sliderValue={sliderValue} />}
        {openSearch && <DialogSearch onClose={() => {
          setOpenSearch(false);
          setAlertText("GO_TO_REGISTRATION");
        }} />}
        {openGps && <DialogDailyData onClose={(flyTo: [number, number] | null) => {
          setOpenGps(false);
          if (map && flyTo) {
            map.flyTo(new LatLng(flyTo[0], flyTo[1]), 12);
          }
        }}
        />}
        <MapTestMonitoring map={map} />
      </MapContainer>
    </div >
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth.auth,
    equipments: state.map.equipments,
    orders: state.map.orders,
    rectangle: state.map.rectangle,
    center: state.map.center,
    klm: state.map.klm,
    allDailyData: state.userData.allDailyData,
    publicData: state.userData.publicData,
    minTime: state.userData.minTime,
    maxTime: state.userData.maxTime,
    equipmentTypes: state.layout.equipments,
    mapState: state.map.mapState,
    selectedItem: state.map.selectedItem,
    testPaths: state.userData.testPaths
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCenter: (coords: LatLng, klm: number) => dispatch({ type: CHANGE_CENTER, coords, klm }),
    setMapState: (state: MapStates, selectedItem?: IEquipment | IOrder) => dispatch({ type: SET_MAP_STATE, state, selectedItem })
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Map);