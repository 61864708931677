import { LatLng, LatLngTuple } from "leaflet";
import { IEquipment, IOrder } from "../userData/types";

export type SearchType = 'EQUIPMENT' | 'ORDER';
export const SET_KLM = 'SET_KLM';
export const SET_CENTER = 'SET_CENTER';
export const CHANGE_CENTER = 'CHANGE_CENTER';
export const SET_SEARCH_EQUIPMENT = 'SET_SEARCH_EQUIPMENT';
export const SET_SEARCH_ORDER = 'SET_SEARCH_ORDER';
export const SEARCH_EQUIPMENT = 'SEARCH_EQUIPMENT';
export const SEARCH_ORDER = 'SEARCH_ORDER';
export const GET_MAP_DATA = 'GET_MAP_DATA';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const SET_SHOW_RECTANGLE = 'SET_SHOW_RECTANGLE';
export const SET_MAP_STATE = 'SET_MAP_STATE';

export interface IUser {
    name?: string;
    login?: string;
    rules?: string;
    passwordHash: string;
    _id?: string;
    deleted?: boolean;
}

export type MapStates = 'SHOW_ONE' | 'SEARCH' | 'TRACK';

export interface MapState {
    loadingSearch: boolean;
    orders: IOrder[];
    equipments: IEquipment[];
    klm: number;
    center: LatLng;
    rectangle: LatLngTuple[];
    showRectangle: boolean;
    selectedItem: IEquipment | IOrder | null;
    mapState: MapStates;
}

export interface SetMapState {
    type: typeof SET_MAP_STATE,
    state: MapStates,
    selectedItem?: IEquipment | IOrder
}

export interface SetShowRectangle {
    type: typeof SET_SHOW_RECTANGLE,
    show: boolean
}

export interface GetMapData {
    type: typeof GET_MAP_DATA,
    coords: LatLngTuple[]
}

export interface SearchEquipment {
    type: typeof SEARCH_EQUIPMENT,
    typeSearch: SearchType,
    typeItem: string,
    klm: number,
    rectangle: LatLngTuple[]
}

export interface SearchOrder {
    type: typeof SEARCH_ORDER,
    typeSearch: SearchType,
    typeItem: string,
    klm: number,
    rectangle: LatLngTuple[]
}

export interface SetMapData {
    type: typeof SET_MAP_DATA,
    equipments: IEquipment[],
    orders: IOrder[]
}

export interface SetKlm {
    type: typeof SET_KLM,
    klm: number
}

export interface SetCenter {
    type: typeof SET_CENTER,
    center: LatLng,
    rectangle: LatLngTuple[]
}

export interface ChangeCenter {
    type: typeof CHANGE_CENTER,
    coords: LatLng,
    klm: number
}

export interface SetSearchEquipments {
    type: typeof SET_SEARCH_EQUIPMENT,
    equipments: IEquipment[]
}

export interface SearchEquipment {
    type: typeof SEARCH_EQUIPMENT;
}

export interface SearchOrder {
    type: typeof SEARCH_ORDER;
}

export interface SetSearchOrders {
    type: typeof SET_SEARCH_ORDER,
    orders: IOrder[]
}

export type MapActionTypes = SetMapData | SetKlm | SetSearchOrders | SetSearchEquipments | SetCenter | SetShowRectangle | SetMapState | SearchEquipment | SearchOrder;