import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Card,
    CardActionArea,
    CardContent,
    CardActions,
    makeStyles,
    Button,
    Typography
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import DialogForText from "./DialogForText";

interface ForumItem {
    _id: string;
    body: string;
    parentId: string;
    type: number;
    childrenCount: number;
    created_at: string;
    user: { name: string, userUrl: string } | null
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '960px',
        margin: 'auto',
        padding: '8px',
        textAlign: 'left'
    },
    cardTheme: {
        margin: '8px',
        position: 'relative'
    },
    cardFolder: {
        margin: '8px',
        backgroundColor: '#daedee',
        position: 'relative'
    },
    theme: {
        fontSize: '14px',
        fontWeight: 400,
        margin: '0'
    },
    folder: {
        fontSize: '16px',
        fontWeight: 700,
        margin: '0'
    },
    message: {
        fontSize: '14px',
        margin: '8px',
        marginTop: '18px'
    },
    forumCard: {
        marginTop: '4px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    createButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    noMessages: {
        textAlign: 'center'
    },
    forumDate: {
        position: 'absolute',
        bottom: '0',
        right: '8px',
        color: '#999',
        fontSize: '12px',
        fontWeight: 700
    },
    author: {
        margin: '0px',
        position: 'absolute',
        top: '0',
        left: '8px',
        fontSize: '10px',
        fontWeight: 900,
        color: '#f50057',
        "&:hover": {
            color: '#fff',
            backgroundColor: '#f50057'
        }
    },
    userLink: {
        textDecoration: 'none'
    }
}));

const Forum = (props: PropsFromRedux) => {
    const classes = useStyles();
    const { user } = props;
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [dialogParent, setDialogParent] = useState('');
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(1);
    const [data, setData] = useState<ForumItem[]>([]);
    const [parent, setParent] = useState<ForumItem | null>(null);

    const folders = data.filter(v => v.type === 0);
    const themes = data.filter(v => v.type === 1);
    const messages = data.filter(v => v.type === 2);
    const onDelete = (id: string) => {
        fetch('/forum-api/delete-thread', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({ id })
        })
    };

    const getCard = (item: ForumItem) => {
        let dateString = '-';
        if (item.created_at) {
            const date = new Date(item.created_at);
            dateString = date.toLocaleDateString() + ' ' + date.getHours() + ':' + date.getMinutes();
        }
        const author = (item.type !== 0) ? (<p onClick={(e: any) => e.stopPropagation()} className={classes.author}>Автор: <a className={classes.userLink} href={item.user ? ('/user/' + item.user.userUrl) : ''}>{item.user?.name}</a></p>) : null;
        const content = (<CardContent>
            <div className={classes.forumCard}>
                <p className={item.type ? classes.theme : classes.folder}>{item.body}</p>
                <p className={item.type ? classes.theme : classes.folder}>{item.childrenCount}</p>
                {author}
            </div>
        </CardContent>);
        return (<Card className={item.type ? classes.cardTheme : classes.cardFolder}>
            {(item.type !== 2) && <CardActionArea onClick={() => history.push('/forum/' + item._id)}>
                {content}
            </CardActionArea>}
            {(item.type === 2) && <><p className={classes.message}>{item.body}</p>{author}</>}
            <div className={classes.forumDate}>{dateString}</div>
            {user?.rules && <CardActions>
                <Button size="small" color="secondary" onClick={() => { onDelete(item._id) }}>
                    Удалить
                </Button>
            </CardActions>}
        </Card>);
    };

    const openDialog = (type: number, parentId: string) => {
        setOpen(true);
        setDialogParent(parentId);
        setType(type);
    };

    const onDialogClose = (type: number, text: string, result: boolean) => {
        setOpen(false);
        if (result) {
            fetchForumData();
        }
    };

    const fetchForumData = () => {
        fetch('/forum-api?parentId=' + ((id === 'main') ? '' : id), {
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(response => response.json())
            .then(result => {
                const data = result.forumItems.sort((v1: any, v2: any) => (v1.type > v2.type) ? 1 : -1);
                setData(data);
                setParent(result.parent || null);
            })
    };

    useEffect(() => {
        fetchForumData();
    }, [id]);

    return (
        <div className={classes.root}>
            <div className={classes.titleWrapper}>
                <h1>Форум</h1>
                {parent && <Button onClick={() => history.push('/forum/' + (parent.parentId || 'main'))} color="primary">Назад</Button>}
            </div>
            {parent && (parent.type === 0) && <h2>Раздел: {parent.body}</h2>}
            {parent && (parent.type === 1) && <h2>Тема: {parent.body}</h2>}
            { folders && !!folders.length && <h3>Разделы</h3>}
            {
                folders.map(item =>
                    getCard(item)
                )
            }
            {
                parent && (parent.type === 0) && (
                    <div className={classes.createButtonWrapper}>
                        <h3>Темы</h3>
                        {user && <Button onClick={() => openDialog(1, parent._id)} color="secondary">Создать тему</Button>}
                    </div>)
            }
            {
                themes.map(item =>
                    getCard(item)
                )
            }
            {
                parent && (parent.type === 1) && (
                    <>
                        <div className={classes.createButtonWrapper}>
                            <h3>Сообщения</h3>
                            {user && <Button onClick={() => openDialog(2, parent._id)} color="secondary">Написать сообщение</Button>}
                        </div>
                        {messages.map(item =>
                            getCard(item)
                        )}
                        {(messages.length === 0) && <Typography variant="body2" color="textSecondary" component="p" className={classes.noMessages}>
                            Нет сообщений
                        </Typography>}
                    </>
                )
            }
            <DialogForText onClose={onDialogClose} open={open} type={type} parentId={dialogParent} />
        </div >
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        user: state.auth.user,
    };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Forum);
