import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { GET_ORDERS, IOrder } from '../../store/userData/types';
import Cards from '../containers/Cards';
import { Button, Fab, Hidden } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
    articleImg: {
    },
    searchButton: {
        position: 'absolute',
        right: '4px',
        top: '70px',
        zIndex: 401
    }
}));

interface OrdersProps {
}

const Orders: React.FC<OrdersProps & PropsFromRedux> = props => {
    const classes = useStyles();
    const { getOrders, auth, orders } = props;
    const history = useHistory();

    useEffect(() => {
        if (auth) {
            getOrders();
        }
    }, [auth]);

    return (
        <>
            <Hidden smUp>
                <Fab color="secondary" aria-label="Поиск" onClick={() => history.push('order/new')} className={classes.searchButton}>
                    <AddIcon />
                </Fab>
            </Hidden>
            <Hidden xsDown>
                <Button variant="contained" color="secondary" aria-label="Поиск" onClick={() => history.push('order/new')} className={classes.searchButton}>
                    Добавить Заказ
                </Button>
            </Hidden>
            <div style={{ marginTop: '16px' }}>
                <Cards auth={auth} items={orders} title={'Заказы'} url={'order'} />
            </div>
        </>
    );
};

interface RootState {
    auth: { auth: boolean };
    userData: { orders: IOrder[] };
}

const mapStateToProps = (state: RootState) => {
    return {
        orders: state.userData.orders,
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getOrders: () => dispatch({ type: GET_ORDERS }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Orders);
