import {SET_TYPES, LayoutActionTypes, LayoutState} from './types'

const initialState: LayoutState = {
    works: [],
    equipments: []
};

export function layoutReducer(
    state = initialState,
    action: LayoutActionTypes
): LayoutState {
    switch (action.type) {
        case SET_TYPES:
            return {
                ...state,
                works: [...action.works],
                equipments: [...action.equipments]
            };
        default:
            return state
    }
}