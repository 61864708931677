import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Hidden } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";
import { SET_TEST_PATHS, SET_TEST_MONITORING, IDailyLocation, IAllDailyData } from "./../store/userData/types";
import { NavLink, useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    showTest: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const TestPresentation: React.FC<PropsFromRedux> = props => {
    const classes = useStyles();
    const [paths, setPaths] = useState(false);
    const [iss, setIss] = useState(false);
    const [issData, setIssData] = useState<IDailyLocation | null>(null);
    const [issInterval, setIssInterval] = useState<any>(null);
    const history = useHistory();

    useEffect(() => {
        if (iss || !issData) {
            props.setTestMonitoring(issData);
        }
    }, [issData]);

    const togglePaths = () => {
        if (!paths) {
            history.push('/map');
            fetch("/api/get-test-paths", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            })
                .then(response => response.json())
                .then(response => {
                    if (response.res === 1) {
                        props.setTestPaths(response.data.map((v: any) => ({ ...v, showPath: true, equipment: response.equips.find((eq: any) => eq._id === v.equipmentId) })));
                    }
                })
        } else {
            props.setTestPaths([]);
        }
        setPaths(!paths);
    };

    const fetchIss = () =>
        fetch("/api/get-test-iss", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.res === 1) {
                    setIssData(response.data);
                }
            });

    const toggleIss = () => {
        if (!iss) {
            history.push('/map');
            if (issInterval) {
                clearInterval(issInterval);
            }
            fetchIss();
            setIssInterval(setInterval(fetchIss
                , 5000))
        } else {
            setIssData(null);
            clearInterval(issInterval);
        }
        setIss(!iss);
    };

    return (
        <>
            <ButtonGroup className={classes.showTest} color="primary" aria-label="outlined primary button group">
                <Button style={{ backgroundColor: paths ? '#ff000024' : '' }} onClick={() => togglePaths()}>Тестовые маршруты</Button>
                <Button style={{ backgroundColor: iss ? '#ff000024' : '' }} onClick={() => toggleIss()}>Тестовый мониторинг МКС</Button>
            </ButtonGroup>
            <Hidden mdUp>
                <Button variant="outlined" style={{ backgroundColor: iss ? '#ff000024' : '', width: '115px', fontSize: '8px' }} onClick={() => toggleIss()}>Презентация GPS-мониторинга</Button>
            </Hidden>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userData: state.userData,
        auth: state.auth.auth,
        foundEquipments: state.map.equipments,
        foundOrders: state.map.orders
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setTestPaths: (testPaths: IAllDailyData[]) => dispatch({ type: SET_TEST_PATHS, testPaths }),
        setTestMonitoring: (testMonitoring: IDailyLocation | null) => dispatch({ type: SET_TEST_MONITORING, testMonitoring })
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(TestPresentation);