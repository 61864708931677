import React, { FormEvent, useEffect } from 'react';
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import { useHistory } from "react-router-dom";
import { LOGIN } from '../../../store/auth/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        form: {
            margin: 'auto',
            maxWidth: '600px',
            marginBottom: '16px'
        },
        button: {
            display: 'block',
            margin: 'auto'
        },
        info: {
            maxWidth: '960px',
            margin: 'auto',
            padding: '8px',
            textAlign: 'left'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center'
        },
        video: {
            maxWidth: "280px",
            width: "100%",
            margin: '0 auto 8px',
            height: "200px"
        },
        textField: {
            margin: theme.spacing(1),
        },
    }));



const ResetPassword = (props: PropsFromRedux) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState<string>('');
    const [showText, setShowText] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const sendResetPassword = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        fetch("/api/reset", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ email })
        })
            .then(response => response.json())
            .then(response => {
                if (response.result === 1) {
                    setShowText(true);
                    setLoading(false);
                } else {
                    setError(true);
                    setLoading(false);
                }
            })
    };
    return (
        <>
            {!showText && <form className={classes.form} onSubmit={(e) => sendResetPassword(e)}>
                <p>Введите почту указанную при регистрации.</p>
                <div>
                    <TextField error={!re.test(email)} helperText={!re.test(email) && "Введите корректную электронную почту"} fullWidth className={classes.textField} label="Email (Электронная почта)" variant="outlined" onChange={(e) => setEmail(e.target.value as string)} />
                </div>
                <Button type='submit' className={classes.button} variant="contained" color="secondary" disabled={!re.test(email)}>Отправить письмо</Button>
                <p>На указанную почту будет отправлено письмо со ссылкой для смены пароля в системе</p>
            </form>}
            {showText && <p>Письмо отправлено на почту {email}. Перейдите по ссылке из письма  от Отправителя <b>info@mazuta.pro</b> и смените пароль</p>}
            {error && <p>Ошибка при отправке письма</p>}
            {loading && <p>Отправка письма</p>}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        auth: state.auth.auth,
        message: state.auth.message
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLogin: (email: string, password: string) => dispatch({ type: LOGIN, email, password }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ResetPassword);
