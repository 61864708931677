import React, { useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { RootState } from '../../store';
import { useParams } from "react-router-dom";
import {
    makeStyles,
    Card,
    CardActionArea,
    CardContent,
    Grid, Button,
    Typography
} from "@material-ui/core";
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IEquipmentPhoto } from '../../store/userData/types';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0
    },
    title: {
        marginTop: "8px",
        textAlign: "center",
        fontSize: '1.0rem'
    },
    buttons: {
        margin: '8px'
    },
    image: {
        maxWidth: "300px",
        margin: '8px'
    },
    card: {
        cursor: 'pointer'
    }
}));


interface Props {
    id: string;
}

const DailyDetails: React.FC<Props & PropsFromRedux> = props => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const { allDailyData, publicData } = props;
    const [currentImage, setCurrentImage] = useState<IEquipmentPhoto | null>(null);
    const [open, setOpen] = React.useState(false);

    const photos = id === 'public' ? publicData : allDailyData.find(item => item.equipmentId === id);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid
                className={classes.root}
                container
                direction="row"
                alignItems="center"
                spacing={2}
            >
                {photos?.photos.map((item, index) => (
                    <Grid item xs={6} sm={3} md={2} lg={2} key={index}>
                        <Card className={classes.card} onClick={() => { setCurrentImage(item); setOpen(true); }}>
                            <CardActionArea>
                                <img src={'/' + item.photoIconURL} alt={'123'} />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {(new Date(item.timestamp)).toLocaleString()}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {currentImage &&
                <Dialog
                    fullWidth
                    maxWidth={'xl'}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">{(new Date(currentImage.timestamp)).toLocaleString()}</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12}><img src={'/' + currentImage.photoURL} alt={'123'} /></Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                    </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        allDailyData: state.userData.allDailyData,
        publicData: state.userData.publicData
    };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DailyDetails);
