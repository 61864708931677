import { LatLngTuple } from 'leaflet';
import { call, put, takeLatest } from 'redux-saga/effects'
import { LatLng } from "leaflet";
import { getRectangle } from "./reducers";
import {
    SearchOrder,
    SearchEquipment,
    SearchType,
    SET_MAP_DATA,
    SEARCH_EQUIPMENT,
    SEARCH_ORDER,
    SET_CENTER,
    CHANGE_CENTER,
    ChangeCenter
} from './types'

const getCompasObj = (coords: LatLngTuple[]) => {
    return {north: coords[1][0].toString(), south: coords[0][0].toString(), west: coords[0][1].toString(), east: coords[1][1].toString()};
}

function* changeCenterSaga(action: ChangeCenter) {
    try {
        const rectangle = getRectangle(action.coords, action.klm);
        yield put({type: SET_CENTER, center: new LatLng(action.coords.lat || 0, action.coords.lng || 0), rectangle});

        const result = yield call(getMapData, rectangle);
        const equipsObj = result.equipments;
        let equipsments: any[] = [];
        for (const key in equipsObj) {
            equipsments = equipsments.concat(equipsObj[key]);
        }
        return yield put({type: SET_MAP_DATA, equipments: [...equipsments], orders: result.orders || []});
    } catch (e) {
    }
}

function* searchEquipmentSaga(action: SearchEquipment) {
    try {
        const result = yield call(searchEquipments, action.typeSearch, action.typeItem, action.klm, action.rectangle);
         return yield put({type: SET_MAP_DATA, equipments: result.equipments, orders: result.orders || []});
    } catch (e) {
    }
}

function* searchOrderSaga(action: SearchOrder) {
    try {
        const result = yield call(searchOrders, action.typeSearch, action.typeItem, action.klm, action.rectangle);
        return yield put({type: SET_MAP_DATA, orders: result.works, equipments: result.equipments || []});
    } catch (e) {
    }
}

function* mapSaga() {
    yield takeLatest(CHANGE_CENTER, changeCenterSaga);
    yield takeLatest(SEARCH_EQUIPMENT, searchEquipmentSaga);
    yield takeLatest(SEARCH_ORDER, searchOrderSaga);
}

function searchEquipments(typeSearch: SearchType, value: string, klm: number, rectangle: LatLngTuple[]) {
    const type = typeSearch === 'EQUIPMENT' ? '0' : '1';
    return fetch("/api/searchEquipment?" + new URLSearchParams({ ...getCompasObj(rectangle), value, type }).toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function searchOrders(typeSearch: SearchType, value: string, klm: number, rectangle: LatLngTuple[]) {
    const type = typeSearch === 'EQUIPMENT' ? '0' : '1';
    return fetch("/api/searchWork?" + new URLSearchParams({ ...getCompasObj(rectangle), value, type }).toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

function getMapData(coords: LatLngTuple[]) {
    return fetch("/api/get-all-data?" + new URLSearchParams({ ...getCompasObj(coords)}).toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
}

export default mapSaga;