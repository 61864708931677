import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect, ConnectedProps } from "react-redux";
import SearchEquipments from './SearchEquipments';
import SearchOrders from './SearchOrders';
import { SET_SHOW_RECTANGLE, MapStates, SET_MAP_STATE } from '../../store/map/types';
import { RootState } from '../../store';

interface Props {
    onClose: () => void;
}

const DialogSearch = (props: Props & PropsFromRedux) => {
    const [equipmentOrOrder, setEquipmentOrOrder] = useState(false);
    const [initState, setInitState] = useState(false);

    useEffect(() => {
        props.setMapState('SEARCH');
        props.setShowRectangle(true);
        setInitState(true);
    }, []);

    useEffect(() => {
        initState && !props.loadingSearch && props.onClose();
    }, [props.loadingSearch]);

    return (
        <Dialog
            open={true}
            aria-labelledby="draggable-dialog-title"
            fullWidth={true}
            maxWidth={'md'}
        >

            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {!equipmentOrOrder ? "Поиск техники" : "Поиск работы"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={1} justify="center">
                        <Grid item>
                            <Button size='small' variant="contained" color={!equipmentOrOrder ? "secondary" : undefined} onClick={() => setEquipmentOrOrder(false)}>
                                Техники
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button size='small' variant="contained" color={equipmentOrOrder ? "secondary" : undefined} onClick={() => setEquipmentOrOrder(true)}>
                                Работы
                            </Button>
                        </Grid>
                    </Grid>
                    {!equipmentOrOrder && <SearchEquipments />}

                    {equipmentOrOrder && <SearchOrders />}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.loadingSearch && <CircularProgress color="secondary" />}
                {!props.loadingSearch && <Button size='small' autoFocus color="primary" onClick={props.onClose}>
                    Скрыть
                </Button>}
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        loadingSearch: state.map.loadingSearch
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setShowRectangle: (show: boolean) => dispatch({ type: SET_SHOW_RECTANGLE, show }),
        setMapState: (state: MapStates) => dispatch({ type: SET_MAP_STATE, state }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DialogSearch);