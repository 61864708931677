import { LatLng } from "leaflet";
import { IEquipment } from "../userData/types";

export const SET_USER = 'SET_USER';
export const SET_AUTH = 'SET_AUTH';
export const SIGNUP = 'SIGNUP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const SEARCH_USER_NAME = 'SEARCH_USER_NAME';
export const SET_FOUNDED_USERS = 'SET_FOUNDED_USERS';
export const ADD_PARTNER = 'ADD_PARTNER';
export const SAVE_PARTNERS_PERMISSIONS = 'SAVE_PARTNERS_PERMISSIONS';
export const CHECK_NICKNAME = 'CHECK_NICKNAME';
export const SET_CONFIRMED_NICKNAME = 'SET_CONFIRMED_NICKNAME';
export const SET_NICKNAME = 'SET_NICKNAME';

export interface IUser {
    name?: string;
    login?: string;
    rules?: string;
    passwordHash: string;
    usersInCommand: IUserInCommand[];
    _id?: string;
    deleted?: boolean;
}

export interface IUserInCommand {
    name: string;
    id: string;
    equipmentIds: string[];
}

export interface AuthState {
    auth: boolean;
    user: IUser | null;
    message: string;
    exactlyUser: string;
    similarUsers: string[];
    partners: IPartner[];
    confirmedNickname: string;
    nicknameMessage: string;
    equipments: IEquipment[];
}

export interface IPartner { id: string, name: string, permissions: string[] }

export interface SetNickname {
    type: typeof SET_NICKNAME,
    name: string
}

export interface SetConfirmedNickname {
    type: typeof SET_CONFIRMED_NICKNAME,
    name: string,
    message: string
}

export interface CheckNickname {
    type: typeof CHECK_NICKNAME,
    name: string
}

export interface SavePartnersPermissions {
    type: typeof SAVE_PARTNERS_PERMISSIONS,
    partners: IPartner[]
}

export interface AddPartner {
    type: typeof ADD_PARTNER,
    name: string
}

export interface SetAuth {
    type: typeof SET_AUTH,
    user: IUser | null,
    auth: boolean,
    partners: IPartner[],
    message: string
}
export interface SearchUserName {
    type: typeof SEARCH_USER_NAME,
    name: string
}
export interface SetFoundedUsers {
    type: typeof SET_FOUNDED_USERS,
    similarUsers: string[]
}

export interface Login {
    type: typeof LOGIN,
    email: string,
    password: string
}

export interface Signup {
    type: typeof SIGNUP,
    email: string,
    password: string
}

export interface Logout {
    type: typeof LOGOUT,
}

export interface GetUser {
    type: typeof GET_USER,
}

export type AuthActionTypes = SetAuth | Logout | Login | GetUser | SetFoundedUsers | SetConfirmedNickname;