import { makeStyles } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import Cards from '../containers/Cards';
import { RootState } from '../../store';
import { Link } from "react-router-dom";
import {
    Button
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));


interface SearchProps {
}

const Search = (props: SearchProps & PropsFromRedux) => {
    const classes = useStyles();

    return (
        <>
            {!(props.equipments.length || props.equipments.length) &&
                <>
                    <h3>Нет результатов в выбранной области на карте</h3>
                    {props.auth ?
                        <>
                            <h4>Добавьте свою технику или заказы в систему и станьте первым исполнителем или заказчиком в данной местности</h4>
                            <Link to={'/equipment/new'}>
                                <Button color="primary">Добавить Технику</Button>

                            </Link>
                            <Link to={'/order/new'}>
                                <Button color="secondary">Добавить Заказ</Button>
                            </Link>
                        </> :
                        <>
                            <h4>Зарегистрируйтесь или войдите в систему.</h4>
                            <h4>Затем Вы сможете добавить свою технику или заказы в систему и стать первым исполнителем или заказчиком в данной местности</h4>
                            <Link to={'/signup'}>
                                <Button color="primary">Регистрация</Button>

                            </Link>
                            <Link to={'/login'}>
                                <Button color="secondary">Вход</Button>
                            </Link>
                        </>}
                </>
            }
            {(!!(props.equipments.length || props.equipments.length)) &&
                <>
                    <Cards auth={false} items={props.equipments} title={'Техника'} url={'equipment'} search={true} />
                    {!props.equipments.length && <h4>Нет Техники в выбранном на Карте квадрате</h4>}
                    <Cards auth={false} items={props.orders} title={'Заказы'} url={'equipment'} search={true} />
                    {!props.orders.length && <h4>Нет Заказов в выбранном на Карте квадрате</h4>}
                </>
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        equipments: state.map.equipments,
        orders: state.map.orders,
        auth: state.auth.auth
    };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Search);
