import React, { useEffect, useState, ReactDOM, useMemo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../store";
import { IEquipment, IEquipmentCoord, IAllDailyData } from "../../store/userData/types";
import { connect, ConnectedProps } from "react-redux";
import { Polyline, Marker, Circle, Popup } from 'react-leaflet';
import { divIcon } from 'leaflet';
import { LatLng } from 'leaflet';

const lineOptions = { color: 'red' };

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));

interface Props {
    map: any;
}


const MapTestMonitoring = (props: Props & PropsFromRedux) => {
    const classes = useStyles();
    const [follow, setFollow] = useState(true);
    const icon = useMemo(() => divIcon({
        html: `<div style="padding: 4px;border-radius: 4px;position: absolute;left: -150px;">
                    <iframe width="300" height="200" src="https://www.youtube.com/embed/DDU-rZs-Ic4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                   </div>`
    }), []);
    const currentSlideLocations: {
        equipment: IEquipment | null;
        location: IEquipmentCoord
    }[] = [];
    const markers: { coords: [number, number], timestamp: number }[] = [];

    if (!props.testMonitoring) {
        return <></>;
    }
    let time = 0;
    const line = props.testMonitoring.locations.map((location: IEquipmentCoord, index: number) => {
        if (time + (300) < location.timestamp) {
            time = location.timestamp;
            markers.push({ coords: location.coords, timestamp: location.timestamp * 1000 });
        }
        return location.coords;
    });

    const getNumber = (a: number) => a < 10 ? '0' + a : a;

    const getIcon = (date: number) => {
        const icon = document.createElement(`div`);
        icon.className = 'equip-icon';
        icon.style.cssText = 'width: fit-content;background-color: #3f51b5;padding: 4px;border-radius: 4px;position: absolute;left: -15px;color: white;font-size: 0.875rem;font-weight: 500;text-transform: uppercase;';
        const d = new Date(date);
        icon.textContent = getNumber(d.getHours()) + ':' + getNumber(d.getMinutes()) + ':' + getNumber(d.getSeconds());
        return divIcon({
            html: icon
        });
    };

    if (props.map) {
        props.map.flyTo(new LatLng(line[line.length - 1][0], line[line.length - 1][1]));
    }

    currentSlideLocations.push({
        equipment: null,
        location: {
            timestamp: props.testMonitoring.locations[props.testMonitoring.locations.length - 1].timestamp * 1000,
            coords: [line[line.length - 1][0], line[line.length - 1][1]]
        }
    });
    return (
        <>
            <Circle center={[line[line.length - 1][0], line[line.length - 1][1]]} pathOptions={{ color: 'blue' }} radius={2114000} opacity={0}>
                <Popup>Область видимости МКС</Popup>
            </Circle>
            <Polyline weight={3} pathOptions={lineOptions} positions={line} />
            {markers.map((item, index) =>
                <Marker
                    key={index}
                    position={item.coords}
                    title={new Date(item.timestamp).toLocaleString()}
                    icon={getIcon(item.timestamp)}
                >
                </Marker>
            )
            }
            {currentSlideLocations.map((item, index) =>
                <Marker
                    key={index}
                    position={item.location.coords}
                    title={new Date(item.location.timestamp).toLocaleString()}
                    icon={icon}
                >
                </Marker>
            )
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        testMonitoring: state.userData.testMonitoring
    };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MapTestMonitoring);
