import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import { Alert as AlertUi } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Fab } from "@material-ui/core";
import { Link } from "react-router-dom";

export type Text = "CLICK_ON_MAP" | "GO_TO_FILTER" | "GO_TO_REGISTRATION" | '';

const useStyles = makeStyles((theme) => ({
    alert: {
        position: 'absolute',
        zIndex: 401,
        bottom: '8px',
        right: '0px'
    }
}));


interface AlertProps {
    text: Text;
    buttonClick: () => void;
}

const Alert = (props: AlertProps) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(true);
    }, [props.text]);

    const classes = useStyles();

    return (
        <div className={classes.alert}>
            <Collapse in={!!props.text && open}>
                <AlertUi action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                } severity="info">
                    {(props.text === "CLICK_ON_MAP") && <strong>Выберите область на карте (левый клик)</strong>}
                    {(props.text === "GO_TO_FILTER") &&
                        <strong>Настройте фильтр поиска
                            <div style={{ display: 'inline-block', width: '30px' }}></div>
                            <Fab size='small' style={{ position: 'absolute', right: '30px', top: '4px' }} color='secondary'>
                                <SearchIcon onClick={() => props.buttonClick()} />
                            </Fab>
                        </strong>
                    }
                    {(props.text === "GO_TO_REGISTRATION") &&
                        <strong>
                            Нет нужных результатов? Оставьте Вашу Технику или Заказы на доске объявлений.
                            <Link to={'/signup'} style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" color="secondary" size="small">Регистрация</Button>
                            </Link> - Техника - Создать Технику
                        </strong>
                    }
                </AlertUi>
            </Collapse>
        </div>
    );
};

export default Alert;
