import React, { FormEvent, useEffect } from 'react';
import { Button, Typography } from "@material-ui/core";
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import { LOGIN } from '../../../store/auth/types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        form: {
            margin: 'auto',
            maxWidth: '600px',
            marginBottom: '16px'
        },
        button: {
            display: 'block',
            margin: 'auto'
        },
        info: {
            maxWidth: '960px',
            margin: 'auto',
            padding: '8px',
            textAlign: 'left'
        },
        flex: {
            display: 'flex',
            justifyContent: 'center'
        },
        video: {
            maxWidth: "280px",
            width: "100%",
            margin: '0 auto 8px',
            height: "200px"
        },
        textField: {
            margin: theme.spacing(1),
        },
    }));

const NewPassword = (props: PropsFromRedux) => {
    const classes = useStyles();
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showText, setShowText] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { email, token } = useParams<{ email: string, token: string }>();
    const history = useHistory();
    const disabled = !(password.length > 5) || (password !== repeatPassword);

    const sendNewPassword = (e: FormEvent) => {
        e.preventDefault();
        fetch("/api/resetSave", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ email, token, newPassword: password })
        })
            .then(response => response.json())
            .then(response => {
                if (response.result === 1) {
                    setShowText(true);
                    setLoading(false);
                } else {
                    setError(true);
                    setLoading(false);
                }
            })
    };
    return (
        <>
            {!showText && <form className={classes.form} onSubmit={(e) => sendNewPassword(e)}>
                <Typography variant="h4" component="h2">Введите новый пароль</Typography>
                <div>
                    <FormControl fullWidth className={classes.textField} variant="outlined" error={!(password.length > 5)}>
                        <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value as string)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                        <FormHelperText id="component-helper-text">{!(password.length > 5) && "Введите пароль более 5 символов"}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth className={classes.textField} variant="outlined" error={(password !== repeatPassword)}>
                        <InputLabel htmlFor="outlined-adornment-password">Повторный ввод пароля</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={repeatPassword}
                            onChange={(e: any) => setRepeatPassword(e.target.value as string)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={210}
                        />
                        <FormHelperText id="component-helper-text">{(password !== repeatPassword) && "Неправильный повторный ввод пароля"}</FormHelperText>
                    </FormControl>
                </div>
                <Button disabled={disabled} type='submit' className={classes.button} variant="contained" color="secondary">Установить новый пароль</Button>
                <Typography variant="h6" component="h6">{props.message}</Typography>
            </form>}
            {showText && <p>Смена пароля завершена успешно</p>}
            {error && <p>Ошибка сброса пароля...</p>}
            {loading && <p>Запущен процесс смены пароля....</p>}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        auth: state.auth.auth,
        message: state.auth.message
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLogin: (email: string, password: string) => dispatch({ type: LOGIN, email, password }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(NewPassword);
