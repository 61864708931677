import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { GET_EQUIPMENTS, IEquipment } from '../../store/userData/types';
import Cards from '../containers/Cards';
import { Button, Hidden, Fab } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: any) => ({
    articleImg: {
    },
    searchButton: {
        position: 'absolute',
        right: '4px',
        top: '70px',
        zIndex: 401
    }
}));

interface EquipmentsProps {
}

const Equipments: React.FC<EquipmentsProps & PropsFromRedux> = props => {
    const classes = useStyles();
    const history = useHistory();
    const { getEquipments, auth, equipments } = props;

    useEffect(() => {
        if (auth) {
            getEquipments();
        }
    }, [auth]);

    return (
        <>
            <Hidden smUp>
                <Fab color="secondary" aria-label="Поиск" onClick={() => history.push('equipment/new')} className={classes.searchButton}>
                    <AddIcon />
                </Fab>
            </Hidden>
            <Hidden xsDown>
                <Button variant="contained" color="secondary" aria-label="Поиск" onClick={() => history.push('equipment/new')} className={classes.searchButton}>
                    Добавить Технику
                </Button>
            </Hidden>
            <div style={{ marginTop: '16px' }}>
                <Cards auth={auth} items={equipments} title={'Техника'} url={'equipment'} />
            </div>
        </>
    );
};

interface RootState {
    auth: { auth: boolean };
    userData: { equipments: IEquipment[] };
}

const mapStateToProps = (state: RootState) => {
    return {
        equipments: state.userData.equipments,
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getEquipments: () => dispatch({ type: GET_EQUIPMENTS })
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Equipments);
