import {
    Hidden,
    makeStyles,
    AppBar,
    Drawer,
    useTheme
} from "@material-ui/core";
import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import TopToolbar from "./TopToolbar";
import { connect, ConnectedProps } from "react-redux";
import { GET_USER, IUser } from "./../store/auth/types";
import { GET_TYPES } from "./../store/layout/types";
import { RootState } from "./../store";
import { useLocation } from 'react-router-dom';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps & PropsFromRedux> = props => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const theme = useTheme();
    const location = useLocation();
    const pathname = location.pathname.split('/');
    const { equipmentTypes, workTypes } = props;
    const seo = equipmentTypes.filter(v => v.seoText).concat(workTypes.filter(v => v.seoText));
    const urlType = ((pathname[1] === 'poisk-raboty') || (pathname[1] === 'arenda')) ? pathname[2] : '';

    const handleDrawerToggle = (closeIfOpen: boolean) => {
        if (closeIfOpen) {
            if (mobileOpen) {
                setMobileOpen(false);
            }
        } else {
            setMobileOpen(!mobileOpen);
        }
    };

    useEffect(() => {
        props.getUser();
        props.getTypes(urlType);
    }, []);

    const drawer = (<Sidebar closeSidebar={(close) => handleDrawerToggle(close)} />);

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <TopToolbar auth={props.auth} onToggle={() => handleDrawerToggle(false)} />
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden lgUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={() => handleDrawerToggle(false)}
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{ keepMounted: true }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                    <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open> {drawer} </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
                {seo && (seo.length > 0) && seo[0].seoText && <>
                    <h3>{seo[0].h3}</h3>
                    <p>{seo[0].seoText}</p>
                </>}
            </main>
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.auth.user,
        auth: state.auth.auth,
        workTypes: state.layout.works,
        equipmentTypes: state.layout.equipments
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getTypes: (urlType: string) => dispatch({ type: GET_TYPES, urlType }),
        getUser: () => dispatch({ type: GET_USER }),

    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Layout);

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({

    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        backgroundColor: '#fff',
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        overflow: 'hidden',
        backgroundColor: '#f9f9f9',
        flexGrow: 1,
        minHeight: '100vh'
    },
}));
