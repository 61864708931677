import React, { FormEvent, useEffect } from 'react';
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store";
import { useHistory } from "react-router-dom";
import { SIGNUP } from '../../../store/auth/types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    form: {
        margin: 'auto',
        maxWidth: '280px',
        marginBottom: '16px'
    },
    textField: {
        margin: '8px'
    },
    button: {
        display: 'block',
        margin: 'auto'
    },
    info: {
        maxWidth: '960px',
        margin: 'auto',
        padding: '8px',
        textAlign: 'left'
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    video: {
        maxWidth: "280px",
        width: "100%",
        margin: '0 auto 8px',
        height: "200px"
    },
});


interface SignupProps {
}

const Signup = (props: SignupProps & PropsFromRedux) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const history = useHistory();
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const disabled = !(password.length > 5) || !re.test(email) || (password !== repeatPassword);
    const [showPassword, setShowPassword] = React.useState(false);

    useEffect(() => {
        if (props.auth) {
            history.push('/equipments');
        }
    }, [props.auth, props.message]);

    const sendSignup = (e: FormEvent) => {
        e.preventDefault();
        props.onSignup(email, password);
    };
    return (
        <>
            <form className={classes.form} onSubmit={(e) => sendSignup(e)}>
                <Typography variant="h4" component="h2">Регистрация</Typography>
                <TextField error={!re.test(email)} helperText={!re.test(email) && "Введите корректную электронную почту"} fullWidth className={classes.textField} label="Email (Электронная почта)" variant="outlined" onChange={(e) => setEmail((e.target.value as string).trim())} />
                <FormControl fullWidth className={classes.textField} variant="outlined" error={!(password.length > 5)}>
                    <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value as string)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        event.preventDefault();
                                    }}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                    <FormHelperText id="component-helper-text">{!(password.length > 5) && "Введите пароль более 5 символов"}</FormHelperText>
                </FormControl>

                <FormControl fullWidth className={classes.textField} variant="outlined" error={(password !== repeatPassword)}>
                    <InputLabel htmlFor="outlined-adornment-password">Повторный ввод пароля</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={repeatPassword}
                        onChange={(e: any) => setRepeatPassword(e.target.value as string)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        event.preventDefault();
                                    }}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={210}
                    />
                    <FormHelperText id="component-helper-text">{(password !== repeatPassword) && "Неправильный повторный ввод пароля"}</FormHelperText>
                </FormControl>

                <Button disabled={disabled} type='submit' className={classes.button} variant="contained" color="secondary">Зарегистрироваться и войти</Button>
                <Typography variant="h6" component="h6">{props.message}</Typography>
            </form>
            <div>
                <Button className={classes.button} variant="contained" color="primary" onClick={() => history.push('/login')}>Уже есть аккаунт</Button>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        auth: state.auth.auth,
        message: state.auth.message
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSignup: (email: string, password: string) => dispatch({ type: SIGNUP, email, password }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Signup);
