import { IEquipment, IOrder, ADD_TO_FAVORITE } from "../../store/userData/types";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center'
    },
    image: {
        maxHeight: '300px'
    },
    imageWrapper: {
        overflow: 'auto',
        width: 'fit-content',
        maxWidth: '90vw'
    }
}));


interface MapPopupProps {
    item: IEquipment | IOrder;
    type: number;
}

const MapPopup = (props: MapPopupProps & PropsFromRedux) => {
    const classes = useStyles();
    const { item, type } = props;
    const history = useHistory();

    return (
        <>
            {(type === 2) && <div className={classes.imageWrapper}><img src={'/' + item} alt={'Фото'} /></div>}
            {(type !== 2) && <div className={classes.root}>
                <h3>{item.name}</h3>
                {item.icoUrl && <img src={item.icoUrl} alt="Картинка" className={classes.image} />}
                <h3>{item.phone}</h3>
                <p style={{ maxHeight: '200px', overflowY: 'auto', width: '260px' }}>{item.description}</p>
                <Button size="small" color="secondary" variant={'contained'} onClick={() => { history.push('/user/' + item.userUrl) }}>Автопарк пользователя</Button>
                <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                        if (props.auth) {
                            props.addToFavorite(props.type, item._id!);
                        } else {
                            history.push('/signup');
                        }
                    }}>
                    В Избранное
                </Button>
            </div>
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        auth: state.auth.auth,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addToFavorite: (type: number, id: string) => dispatch({ type: ADD_TO_FAVORITE, typeFav: type, id }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MapPopup);
