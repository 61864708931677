import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Card, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    CardActionArea,
    CardContent,
    CardMedia, CardActions, FormControlLabel, Checkbox, TextField, FormControl, Typography,
    Grid
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { GET_DAILY_DATA, SET_SHOW_PHOTO, SET_SHOW_PATH, SET_PATH_INTERVAL, SET_TIME_PATH } from '../../store/userData/types';
import { LatLng } from 'leaflet';
import { SET_SHOW_RECTANGLE } from '../../store/map/types';
import { RootState } from "../../store";
import { CHANGE_CENTER, MapStates, SET_MAP_STATE } from '../../store/map/types';

interface Props {
    onClose: (flyTo: [number, number] | null) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 0
    },
    title: {
        marginTop: "8px",
        textAlign: "center",
        fontSize: '1.0rem'
    },
    buttons: {
        margin: '8px'
    },
    image: {
        maxWidth: "300px",
        margin: '8px'
    },
    itemText: {
        height: '18px',
        overflow: 'hidden',
    },
    card: {
        cursor: 'pointer'
    },
    media: {
        height: 200
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    }
}));

const DialogDailyData = (props: Props & PropsFromRedux) => {
    const classes = useStyles();
    const { refreshDaily, allDailyData, setShowRectangle, publicData } = props;
    const history = useHistory();
    const arr = publicData ? [...allDailyData, publicData] : [...allDailyData];

    useEffect(() => {
        setShowRectangle(false);
    });

    useEffect(() => {
        props.setMapState('TRACK');
        if (allDailyData.length === 0) {
            refreshDaily();
        }
    }, []);

    const сlearDaily = () => {
        fetch("/api/clear-all-daily", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(response => response.json())
    }

    return (
        <Dialog
            open={true}
            aria-labelledby="draggable-dialog-title"
            fullWidth={true}
            maxWidth={'lg'}
        >

            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Последние записи
            </DialogTitle>
            <DialogContent>
                <>
                    <Button className={classes.buttons} onClick={refreshDaily} variant="outlined" color="primary">Обновить данные</Button>

                    <Grid
                        className={classes.root}
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                    >
                        {arr?.map(item => (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Card className={classes.card}>
                                    <CardActionArea onClick={() => item.photos.length && history.push('/daily/' + item.equipmentId)}>
                                        <CardMedia
                                            className={classes.media}
                                            image={item.equipment.icoUrl?.replace('\\', '/') ? item.equipment.icoUrl?.replace('\\', '/') : 'img/nofoto.jpg'}
                                            title={item.equipment.name}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.itemText} align='center'>
                                                {item.equipment.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p" className={classes.itemText} align='center'>
                                                {item.locations.length} точек. {item.photos.length} фото
                                            </Typography>
                                            {item.equipmentId === 'public' && <h3 style={{ color: 'red' }}>Данные по ссылке</h3>}
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions className={classes.actions}>
                                        <FormControlLabel
                                            control={<Checkbox checked={item.showPath} onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.showPathOnMap(item.equipmentId, event.target.checked)} name="checkedA" />}
                                            label="Маршрут"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={item.showTime} onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.showTimeOnMap(item.equipmentId, event.target.checked)} name="checkedA" />}
                                            label="Время"
                                        />
                                        <FormControl>
                                            <TextField
                                                id="outlined-basic"
                                                label="Интервал, сек"
                                                value={item.pathInterval}
                                                variant="outlined"
                                                type="number"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.changeIntervalOnMap(item.equipmentId, event.target.value ? parseInt(event.target.value) : 3200)}
                                            />
                                        </FormControl>
                                        <FormControlLabel
                                            control={<Checkbox checked={item.showPhoto} onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.showPhotoOnMap(item.equipmentId, event.target.checked)} name="checkedA" />}
                                            label="Фото"
                                        />
                                        {(item.locations.length > 0) && <Button size="small" color="primary" onClick={() => {
                                            props.setCenter(new LatLng(item.locations[0].coords[0], item.locations[0].coords[1]), 1);
                                            props.onClose([item.locations[0].coords[0], item.locations[0].coords[1]]);
                                        }}>
                                            На карту
                                        </Button>}
                                    </CardActions>
                                </Card>

                            </Grid>
                        ))}
                    </Grid>
                    <Button className={classes.buttons} onClick={сlearDaily} variant="outlined" color="secondary">Отправить дневную запись в архив</Button>
                </>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={() => props.onClose(null)}>
                    Скрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        allDailyData: state.userData.allDailyData,
        publicData: state.userData.publicData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        refreshDaily: () => { dispatch({ type: GET_DAILY_DATA }); },
        showPhotoOnMap: (id: string, state: boolean) => { dispatch({ type: SET_SHOW_PHOTO, equipmentId: id, showPhoto: state }); },
        showPathOnMap: (id: string, state: boolean) => { dispatch({ type: SET_SHOW_PATH, equipmentId: id, showPath: state }); },
        showTimeOnMap: (id: string, state: boolean) => { dispatch({ type: SET_TIME_PATH, equipmentId: id, showTime: state }); },
        changeIntervalOnMap: (id: string, interval: number) => { dispatch({ type: SET_PATH_INTERVAL, equipmentId: id, pathInterval: interval }); },
        setCenter: (coords: LatLng, klm: number) => dispatch({ type: CHANGE_CENTER, coords, klm }),
        setShowRectangle: (show: boolean) => dispatch({ type: SET_SHOW_RECTANGLE, show }),
        setMapState: (state: MapStates) => dispatch({ type: SET_MAP_STATE, state }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DialogDailyData);